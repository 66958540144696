import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Coerce UNIX Time Stamp into Time and vice versa */
  Timestamp: { input: any; output: any; }
};

/** Represent a GoodCrowd.org campaign account */
export type TAccount = {
  /** Can receive payments */
  anyPspEnabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Can receive payments via PayPal */
  paypalEnabled: Scalars['Boolean']['output'];
  /** PayPal ID of the connected merchant account */
  paypalId?: Maybe<Scalars['String']['output']>;
  paypalMerchantStatus: Scalars['String']['output'];
  /** PayPal onboarding is pending? */
  paypalPending: Scalars['Boolean']['output'];
  stripeAccount?: Maybe<TStripeAccount>;
  /** Can receive payments via Stripe */
  stripeEnabled: Scalars['Boolean']['output'];
  stripeHolderName?: Maybe<Scalars['String']['output']>;
  /** Stripe onboarding is pending? */
  stripePending: Scalars['Boolean']['output'];
  stripeVerificationStatus: Scalars['String']['output'];
};

/** Autogenerated input type of BrowserEvent */
export type TBrowserEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  eventName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of BrowserEvent. */
export type TBrowserEventPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** A campaign entry returns campaign information */
export type TCampaign = {
  /** Information about PSP connections */
  account: TAccount;
  /** Address cannot be changed */
  addressLocked: Scalars['Boolean']['output'];
  /** The business name this campaign is associated with if any */
  businessName?: Maybe<Scalars['String']['output']>;
  /** The campaigns category */
  category: Scalars['String']['output'];
  /** The city where this campaign is located at */
  city?: Maybe<Scalars['String']['output']>;
  confirmedDonationCount: Scalars['Int']['output'];
  /** The code of the country where this campaign is located at */
  countryAlpha2?: Maybe<Scalars['String']['output']>;
  /** The name of the country where this campaign is located at */
  countryName?: Maybe<Scalars['String']['output']>;
  /** The date when the campaign was created (in milliseconds from midnight of January 1, 1970) */
  createdAt: Scalars['Timestamp']['output'];
  /** The current crop for the original photo. Eg '300x300+20+50' extracts 300x300 area with top-left edge 20,50 */
  crop?: Maybe<Scalars['String']['output']>;
  /** Whether deletion of the campaign record is allowed */
  deletable: Scalars['Boolean']['output'];
  /** The campaign description */
  description: Scalars['String']['output'];
  /** Fetch all donations for this campaign */
  donations: TDonationConnection;
  /** The periods for which a donor report can be requested */
  donorReportReferencePeriods: Array<TReferencePeriod>;
  donorsToNotifyCount: Scalars['Int']['output'];
  forceCaptcha: Scalars['Boolean']['output'];
  /** The donation goal of this campaign (in EUR). Only nullable during submission. */
  goal?: Maybe<Scalars['Float']['output']>;
  /** The donation goal of this campaign (in cents). Only nullable during submission. */
  goalCents?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** The invoice address for this campaign */
  invoiceAddress?: Maybe<TInvoiceAddress>;
  /** the invoices for this campaign */
  invoices: TInvoiceConnection;
  /** The total number of months with invoices */
  invoicesCount: Scalars['Int']['output'];
  /** The User who owns the campaign */
  manager: TUser;
  /** Whether this campaign needs administrative review before being able to receive donations */
  needsRiskReview: Scalars['Boolean']['output'];
  /** the News for this campaign */
  news: TNewsConnection;
  /** The total number of news */
  newsCount: Scalars['Int']['output'];
  /** The full name of the campaign User owning the campaign */
  ownerName: Scalars['String']['output'];
  /** The campaigns attached original version photo */
  photoOriginalUrl?: Maybe<Scalars['String']['output']>;
  /** The campaigns attached photo */
  photoUrl?: Maybe<Scalars['String']['output']>;
  /** The money received by this campaign (in cents) */
  receivedCents: Scalars['Int']['output'];
  /** The money received by this campaign (in cents) via paypal */
  receivedPaypalCents: Scalars['Int']['output'];
  /** The money received by this campaign (in cents) via stripe */
  receivedStripeCents: Scalars['Int']['output'];
  /** The most recent slug path for the URL of this campaign */
  slug: Scalars['String']['output'];
  /** State of this campaign */
  state: Scalars['String']['output'];
  /** The title of this campaign */
  title: Scalars['String']['output'];
  /** The money that was used for paying transaction fee (in cents) */
  transactionFeeCents: Scalars['Int']['output'];
};


/** A campaign entry returns campaign information */
export type TCampaignDonationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<Array<Scalars['String']['input']>>;
};


/** A campaign entry returns campaign information */
export type TCampaignInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A campaign entry returns campaign information */
export type TCampaignNewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** A campaign entry returns campaign information */
export type TCampaignPhotoUrlArgs = {
  variant?: InputMaybe<Scalars['String']['input']>;
};

/** The connection type for Campaign. */
export type TCampaignConnection = {
  /** A list of edges. */
  edges: Array<TCampaignEdge>;
  /** A list of nodes. */
  nodes: Array<TCampaign>;
  /** Information to aid in pagination. */
  pageInfo: TPageInfo;
};

/** Autogenerated input type of CampaignDelete */
export type TCampaignDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of CampaignDelete. */
export type TCampaignDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of CampaignDenunciate */
export type TCampaignDenunciateInput = {
  captchaSolution?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CampaignDenunciate. */
export type TCampaignDenunciatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
};

/** An edge in a connection. */
export type TCampaignEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TCampaign;
};

/** Autogenerated input type of CampaignEvent */
export type TCampaignEventInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  event: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of CampaignEvent. */
export type TCampaignEventPayload = {
  campaign?: Maybe<TCampaign>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
};

/** Autogenerated input type of CampaignInquire */
export type TCampaignInquireInput = {
  captchaSolution?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<Scalars['Boolean']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of CampaignInquire. */
export type TCampaignInquirePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
};

/** Autogenerated input type of CampaignUpdate */
export type TCampaignUpdateInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  crop?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  feesConsent?: InputMaybe<Scalars['Boolean']['input']>;
  goal?: InputMaybe<Scalars['Int']['input']>;
  invoiceAddress?: InputMaybe<TInvoiceAddressInput>;
  newSlug?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  trackingVia?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of CampaignUpdate. */
export type TCampaignUpdatePayload = {
  campaign?: Maybe<TCampaign>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
};

/** A campaign category */
export type TCategory = {
  /** The call to action of this category */
  cta: Scalars['String']['output'];
  /** The description of this category */
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The slug of this category */
  slug: Scalars['String']['output'];
  /** The title of this category */
  title: Scalars['String']['output'];
};

/** Autogenerated input type of ChangePassword */
export type TChangePasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ChangePassword. */
export type TChangePasswordPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  user?: Maybe<TUser>;
};

/** Autogenerated input type of ConfirmEmail */
export type TConfirmEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ConfirmEmail. */
export type TConfirmEmailPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  user?: Maybe<TViewer>;
};

/** Autogenerated input type of ContentReport */
export type TContentReportInput = {
  captchaSolution?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ContentReport. */
export type TContentReportPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
};

/** A donation for a campaign, including the amount */
export type TDonation = {
  amountCents: Scalars['Int']['output'];
  campaign: TCampaign;
  codonationCents: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  confirmedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  donorBirthdate?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  notify: Scalars['Boolean']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  privateDonor: TPrivateDonor;
  publicDonor: TPublicDonor;
  receiptRequested: Scalars['Boolean']['output'];
  refundTriggered: Scalars['Boolean']['output'];
  refundable: Scalars['Boolean']['output'];
  refundedAt?: Maybe<Scalars['Timestamp']['output']>;
  state: Scalars['String']['output'];
  subscribeToOrg: Scalars['Boolean']['output'];
};


/** A donation for a campaign, including the amount */
export type TDonationCodonationCentsArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


/** A donation for a campaign, including the amount */
export type TDonationPrivateDonorArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

/** The connection type for Donation. */
export type TDonationConnection = {
  /** A list of edges. */
  edges: Array<TDonationEdge>;
  /** A list of nodes. */
  nodes: Array<TDonation>;
  /** Information to aid in pagination. */
  pageInfo: TPageInfo;
};

/** An edge in a connection. */
export type TDonationEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TDonation;
};

/** Autogenerated input type of DonationEdit */
export type TDonationEditInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  deleteImage?: InputMaybe<Scalars['Boolean']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  showUser?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of DonationEdit. */
export type TDonationEditPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  privateDonor?: Maybe<TPrivateDonor>;
};

/** Autogenerated input type of DonationMake */
export type TDonationMakeInput = {
  amountCents?: InputMaybe<Scalars['Int']['input']>;
  campaignId: Scalars['ID']['input'];
  captchaSolution?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  codonationCents?: InputMaybe<Scalars['Int']['input']>;
  codonationEdited?: InputMaybe<Scalars['Boolean']['input']>;
  dobDay?: InputMaybe<Scalars['String']['input']>;
  dobMonth?: InputMaybe<Scalars['String']['input']>;
  dobYear?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  receiptRequested?: InputMaybe<Scalars['Boolean']['input']>;
  stripeClientSecret?: InputMaybe<Scalars['String']['input']>;
  stripePaymentIntentId?: InputMaybe<Scalars['String']['input']>;
  stripeSourceId?: InputMaybe<Scalars['String']['input']>;
  subscribeToOrg?: InputMaybe<Scalars['Boolean']['input']>;
  tracking?: InputMaybe<TDonationTrackingInput>;
};

/** Autogenerated return type of DonationMake. */
export type TDonationMakePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donation?: Maybe<TDonation>;
  messages?: Maybe<Array<TFieldError>>;
  paypalOrderId?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
  stripePaymentIntent?: Maybe<TStripePaymentIntent>;
};

/** AB event information for a donation */
export type TDonationTrackingInput = {
  at?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  defaultAmountCents?: InputMaybe<Scalars['Int']['input']>;
  defaultCodonationCents?: InputMaybe<Scalars['Int']['input']>;
  defaultPaymentMethod?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<Scalars['String']['input']>;
  userAgent?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

/** Information about field that didn’t pass validation */
export type TFieldError = {
  /** Field name that caused these errors */
  field: Scalars['String']['output'];
  /** Validation message */
  message: Scalars['String']['output'];
};

/** Autogenerated input type of FinalizePaypal */
export type TFinalizePaypalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
};

/** Autogenerated return type of FinalizePaypal. */
export type TFinalizePaypalPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  paypalErrorKey?: Maybe<Scalars['String']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

/** Represents an invoice reference period for a campaign */
export type TInvoice = {
  corrected: Scalars['Boolean']['output'];
  monthYear: Scalars['String']['output'];
  period: Scalars['String']['output'];
  year: Scalars['String']['output'];
};

/** Represents an invoice address for a user or campaign */
export type TInvoiceAddress = {
  businessName?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  countryAlpha2: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  street: Scalars['String']['output'];
  vatId?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

/** Address details to provide when submitting a campaign */
export type TInvoiceAddressInput = {
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** The connection type for Invoice. */
export type TInvoiceConnection = {
  /** A list of edges. */
  edges: Array<TInvoiceEdge>;
  /** A list of nodes. */
  nodes: Array<TInvoice>;
  /** Information to aid in pagination. */
  pageInfo: TPageInfo;
};

/** An edge in a connection. */
export type TInvoiceEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TInvoice;
};

/** Represents an invoice info for a campaign */
export type TInvoiceInfo = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type TMutation = {
  /** Track whether user accepts cookies or not */
  browserEvent: TBrowserEventPayload;
  /** Delete a campaign object. Only possible in some cases. */
  campaignDelete: TCampaignDeletePayload;
  /** Report the campaign by sending an email to support */
  campaignDenunciate: TCampaignDenunciatePayload;
  /** Transition to a new state */
  campaignEvent: TCampaignEventPayload;
  /** Send an email to the campaign manager */
  campaignInquire: TCampaignInquirePayload;
  /** Update of campaign object */
  campaignUpdate: TCampaignUpdatePayload;
  /** Change user password */
  changePassword: TChangePasswordPayload;
  /** Confirm the email address of a newly registered user */
  confirmEmail: TConfirmEmailPayload;
  /** Report the content by sending an email to support */
  contentReport: TContentReportPayload;
  /** Edit a donation (e.g. add comment in postdonation) */
  donationEdit: TDonationEditPayload;
  /** Make a donation */
  donationMake: TDonationMakePayload;
  /** Finalize a PayPal donation after the buyer has authorized the Order/Payment */
  finalizePaypal: TFinalizePaypalPayload;
  /** Delete the given news object */
  newsDelete: TNewsDeletePayload;
  /** Creation of news object */
  newsMake: TNewsMakePayload;
  /** Unsubscribe the given email from all news */
  newsUnsubscribe: TNewsUnsubscribePayload;
  /** Update of news object */
  newsUpdate: TNewsUpdatePayload;
  /** Reset password with a previously requested token */
  passwordReset: TPasswordResetPayload;
  /** Refund a donation */
  refund: TRefundPayload;
  /** Request a password reset token to be sent to your email */
  requestPasswordReset: TRequestPasswordResetPayload;
  /** Request the redirect URL for paypal merchant setup */
  requestPaypalReferral: TRequestPaypalReferralPayload;
  /** Sends another mail to allow the user to confirm his account */
  resendConfirmation: TResendConfirmationPayload;
  /** Send requested donor report by email */
  sendDonorReport: TSendDonorReportPayload;
  /** Send a thank you message to the donation user */
  sendThankYouMessage: TSendThankYouMessagePayload;
  /** Start a user session */
  signIn: TSignInPayload;
  /** Terminate the current user session */
  signOut: TSignOutPayload;
  /** Sign up a user with email and password */
  signUp: TSignUpPayload;
  stripeKycStartOnboarding: TStartOnboardingPayload;
  stripeKycSubmitIndividualData: TSubmitIndividualDataPayload;
  /** Delete the viewer's User account. Only possible in some cases. */
  userDelete: TUserDeletePayload;
  /** Update user details for the signed in account */
  userUpdate: TUserUpdatePayload;
};


export type TMutationBrowserEventArgs = {
  input: TBrowserEventInput;
};


export type TMutationCampaignDeleteArgs = {
  input: TCampaignDeleteInput;
};


export type TMutationCampaignDenunciateArgs = {
  input: TCampaignDenunciateInput;
};


export type TMutationCampaignEventArgs = {
  input: TCampaignEventInput;
};


export type TMutationCampaignInquireArgs = {
  input: TCampaignInquireInput;
};


export type TMutationCampaignUpdateArgs = {
  input: TCampaignUpdateInput;
};


export type TMutationChangePasswordArgs = {
  input: TChangePasswordInput;
};


export type TMutationConfirmEmailArgs = {
  input: TConfirmEmailInput;
};


export type TMutationContentReportArgs = {
  input: TContentReportInput;
};


export type TMutationDonationEditArgs = {
  input: TDonationEditInput;
};


export type TMutationDonationMakeArgs = {
  input: TDonationMakeInput;
};


export type TMutationFinalizePaypalArgs = {
  input: TFinalizePaypalInput;
};


export type TMutationNewsDeleteArgs = {
  input: TNewsDeleteInput;
};


export type TMutationNewsMakeArgs = {
  input: TNewsMakeInput;
};


export type TMutationNewsUnsubscribeArgs = {
  input: TNewsUnsubscribeInput;
};


export type TMutationNewsUpdateArgs = {
  input: TNewsUpdateInput;
};


export type TMutationPasswordResetArgs = {
  input: TPasswordResetInput;
};


export type TMutationRefundArgs = {
  input: TRefundInput;
};


export type TMutationRequestPasswordResetArgs = {
  input: TRequestPasswordResetInput;
};


export type TMutationRequestPaypalReferralArgs = {
  input: TRequestPaypalReferralInput;
};


export type TMutationResendConfirmationArgs = {
  input: TResendConfirmationInput;
};


export type TMutationSendDonorReportArgs = {
  input: TSendDonorReportInput;
};


export type TMutationSendThankYouMessageArgs = {
  input: TSendThankYouMessageInput;
};


export type TMutationSignInArgs = {
  input: TSignInInput;
};


export type TMutationSignOutArgs = {
  input: TSignOutInput;
};


export type TMutationSignUpArgs = {
  input: TSignUpInput;
};


export type TMutationStripeKycStartOnboardingArgs = {
  input: TStartOnboardingInput;
};


export type TMutationStripeKycSubmitIndividualDataArgs = {
  input: TSubmitIndividualDataInput;
};


export type TMutationUserDeleteArgs = {
  input: TUserDeleteInput;
};


export type TMutationUserUpdateArgs = {
  input: TUserUpdateInput;
};

/** Represents a campaign news item */
export type TNews = {
  body: Scalars['String']['output'];
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  /** Should donors be notified? */
  notify: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
};

/** The connection type for News. */
export type TNewsConnection = {
  /** A list of edges. */
  edges: Array<TNewsEdge>;
  /** A list of nodes. */
  nodes: Array<TNews>;
  /** Information to aid in pagination. */
  pageInfo: TPageInfo;
};

/** Autogenerated input type of NewsDelete */
export type TNewsDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of NewsDelete. */
export type TNewsDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  news?: Maybe<TNews>;
};

/** An edge in a connection. */
export type TNewsEdge = {
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TNews;
};

/** Autogenerated input type of NewsMake */
export type TNewsMakeInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  campaignId: Scalars['ID']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of NewsMake. */
export type TNewsMakePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  news?: Maybe<TNews>;
};

/** Autogenerated input type of NewsUnsubscribe */
export type TNewsUnsubscribeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of NewsUnsubscribe. */
export type TNewsUnsubscribePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of NewsUpdate */
export type TNewsUpdateInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of NewsUpdate. */
export type TNewsUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  news?: Maybe<TNews>;
};

/** Information about pagination in a connection. */
export type TPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of PasswordReset */
export type TPasswordResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Autogenerated return type of PasswordReset. */
export type TPasswordResetPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
};

/** Private details of a Donation creator. */
export type TPrivateDonor = {
  comment?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  paymentMethod: Scalars['String']['output'];
  showUser: Scalars['Boolean']['output'];
  thankYouMessage?: Maybe<Scalars['String']['output']>;
};

/** Public details of a Donation creator. */
export type TPublicDonor = {
  /** Donor name. Null if anonymous or not yet provided by PSP. */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type TQuery = {
  /** Fetch a campaign by its slug */
  campaign: TCampaign;
  /** Fetch all campaign categories */
  categories: Array<TCategory>;
  /** Fetch a category by its slug */
  category: TCategory;
  /** Look up a city name by zip code */
  city?: Maybe<Scalars['String']['output']>;
  /** Fetch a donation by its token */
  donation: TDonation;
  /** Fetch a News item by its id */
  news: TNews;
  /** Search for campaigns. */
  search: TSearch;
  /** Fetch the current user. */
  viewer?: Maybe<TViewer>;
};


export type TQueryCampaignArgs = {
  slug: Scalars['String']['input'];
};


export type TQueryCategoryArgs = {
  slug: Scalars['String']['input'];
};


export type TQueryCityArgs = {
  zipCode: Scalars['String']['input'];
};


export type TQueryDonationArgs = {
  token: Scalars['String']['input'];
};


export type TQueryNewsArgs = {
  id: Scalars['ID']['input'];
};

export type TQuickinfoItem = {
  detailsHtml?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  urlRisky?: Maybe<Scalars['Boolean']['output']>;
};

export enum TQuickinfoType {
  Campaign = 'campaign',
  Dev = 'dev',
  Donation = 'donation',
  User = 'user'
}

/** Represents a reference period */
export type TReferencePeriod = {
  /** Month */
  month: Scalars['Int']['output'];
  /** Year */
  year: Scalars['Int']['output'];
};

/** Autogenerated input type of Refund */
export type TRefundInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  donationId: Scalars['ID']['input'];
};

/** Autogenerated return type of Refund. */
export type TRefundPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donation?: Maybe<TDonation>;
  messages?: Maybe<Array<TFieldError>>;
};

/** Autogenerated input type of RequestPasswordReset */
export type TRequestPasswordResetInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of RequestPasswordReset. */
export type TRequestPasswordResetPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  sentInstructions: Scalars['Boolean']['output'];
};

/** Autogenerated input type of RequestPaypalReferral */
export type TRequestPaypalReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of RequestPaypalReferral. */
export type TRequestPaypalReferralPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of ResendConfirmation */
export type TResendConfirmationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of ResendConfirmation. */
export type TResendConfirmationPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
};

/** Search for campaigns */
export type TSearch = {
  /** return all matching campaigns */
  campaigns: TCampaignConnection;
  /** return total number of matching campaigns */
  totalCount: Scalars['Int']['output'];
};


/** Search for campaigns */
export type TSearchCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};


/** Search for campaigns */
export type TSearchTotalCountArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated input type of SendDonorReport */
export type TSendDonorReportInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  month: Scalars['Int']['input'];
  slug: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};

/** Autogenerated return type of SendDonorReport. */
export type TSendDonorReportPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of SendThankYouMessage */
export type TSendThankYouMessageInput = {
  activateResponseEmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  donationId: Scalars['ID']['input'];
  thankYouMessage?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SendThankYouMessage. */
export type TSendThankYouMessagePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  donation?: Maybe<TDonation>;
  messages?: Maybe<Array<TFieldError>>;
};

/** Autogenerated input type of SignIn */
export type TSignInInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** Autogenerated return type of SignIn. */
export type TSignInPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  oauthPath?: Maybe<Scalars['String']['output']>;
  user?: Maybe<TViewer>;
};

/** Autogenerated input type of SignOut */
export type TSignOutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SignOut. */
export type TSignOutPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SignUp */
export type TSignUpInput = {
  captchaSolution?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of SignUp. */
export type TSignUpPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  user?: Maybe<TViewer>;
};

/** Autogenerated input type of StartOnboarding */
export type TStartOnboardingInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of StartOnboarding. */
export type TStartOnboardingPayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

/** Represent a stripe account */
export type TStripeAccount = {
  accountNumberLastFour?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  /** individual/company/ngo, null if not yet chosen. */
  businessType?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryAlpha2?: Maybe<Scalars['String']['output']>;
  dobDay?: Maybe<Scalars['String']['output']>;
  dobMonth?: Maybe<Scalars['String']['output']>;
  dobYear?: Maybe<Scalars['String']['output']>;
  documentProvided: Scalars['Boolean']['output'];
  fieldsNeeded: Array<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  payoutsEnabled: Scalars['Boolean']['output'];
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Represent a Stripe::PaymentIntent object */
export type TStripePaymentIntent = {
  clientSecret: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isCreditCard: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
};

/** Autogenerated input type of SubmitIndividualData */
export type TSubmitIndividualDataInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  dobDay?: InputMaybe<Scalars['String']['input']>;
  dobMonth?: InputMaybe<Scalars['String']['input']>;
  dobYear?: InputMaybe<Scalars['String']['input']>;
  documentBack?: InputMaybe<Scalars['String']['input']>;
  documentFront?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  stripeConsent?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Autogenerated return type of SubmitIndividualData. */
export type TSubmitIndividualDataPayload = {
  campaign?: Maybe<TCampaign>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
};

/** An user entry, returns basic user information */
export type TUser = {
  /** Avatar URL (150x150) */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** Has pseudonym */
  hasPseudonym?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** The full name of this user */
  name: Scalars['String']['output'];
};

/** Autogenerated input type of UserDelete */
export type TUserDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserDelete. */
export type TUserDeletePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Autogenerated input type of UserUpdate */
export type TUserUpdateInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  crop?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of UserUpdate. */
export type TUserUpdatePayload = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<TFieldError>>;
  user?: Maybe<TViewer>;
};

/** An user entry, returns basic user information */
export type TViewer = {
  /** Avatar URL (original size and dimension) */
  avatarOriginalUrl?: Maybe<Scalars['String']['output']>;
  /** Avatar URL (150x150) */
  avatarUrl?: Maybe<Scalars['String']['output']>;
  campaign: TCampaign;
  /** the Campaigns managed by this user */
  campaigns: TCampaignConnection;
  /** Whether the user has confirmed his email */
  confirmed: Scalars['Boolean']['output'];
  /** The date this user created an account */
  createdAt: Scalars['String']['output'];
  /** The current crop for the avatar. Eg '300x300+20+50' extracts 300x300 area with top-left edge 20,50 */
  crop?: Maybe<Scalars['String']['output']>;
  /** Whether deletion of the viewer record is allowed */
  deletable: Scalars['Boolean']['output'];
  /** The email of this user */
  email: Scalars['String']['output'];
  /** The first name of this user */
  firstName: Scalars['String']['output'];
  /** whether Invoice::Address data has already been entered */
  hasInvoiceAddress: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** the invoice items infos of a slug/period */
  invoiceInfos: Array<TInvoiceInfo>;
  /** The last name of this user */
  lastName: Scalars['String']['output'];
  /** The full name of this user */
  name: Scalars['String']['output'];
  /** Name cannot be changed */
  nameLocked: Scalars['Boolean']['output'];
  /** the one and only created campaign for the manager/orga */
  onboardingCampaign?: Maybe<TCampaign>;
  /** quickinfo UI elements */
  quickinfos: Array<TQuickinfoItem>;
  /** whether to TRY to fetch quickinfos (fetching re-checks permissions) */
  showQuickinfos: Scalars['Boolean']['output'];
};


/** An user entry, returns basic user information */
export type TViewerCampaignArgs = {
  slug: Scalars['String']['input'];
};


/** An user entry, returns basic user information */
export type TViewerCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** An user entry, returns basic user information */
export type TViewerInvoiceInfosArgs = {
  period: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};


/** An user entry, returns basic user information */
export type TViewerQuickinfosArgs = {
  recordId?: InputMaybe<Scalars['ID']['input']>;
  type: TQuickinfoType;
};

export type TCampaignFieldsFragment = { id: string, category: string, city?: string | null, deletable: boolean, description: string, goal?: number | null, addressLocked: boolean, photoUrl?: string | null, photoOriginalUrl?: string | null, crop?: string | null, slug: string, title: string, state: string, donorsToNotifyCount: number, needsRiskReview: boolean, donorReportReferencePeriods: Array<{ year: number, month: number }> };

export type TInvoiceAddressFragment = { invoiceAddress?: { id: string, businessName?: string | null, city: string, countryAlpha2: string, firstName: string, lastName: string, street: string, vatId?: string | null, zipCode: string } | null };

export type TManagedAccountFragment = { account: { id: string, anyPspEnabled: boolean, paypalEnabled: boolean, paypalMerchantStatus: string, stripeEnabled: boolean, stripeVerificationStatus: string, stripeAccount?: { id: string, accountNumberLastFour?: string | null, bankName?: string | null, businessType?: string | null, city?: string | null, countryAlpha2?: string | null, dobDay?: string | null, dobMonth?: string | null, dobYear?: string | null, documentProvided: boolean, fieldsNeeded: Array<string>, firstName?: string | null, lastName?: string | null, payoutsEnabled: boolean, street?: string | null, zipCode?: string | null } | null } };

export type TNewsFieldsFragment = { id: string, createdAt: any, title: string, body: string };

export type TPageInfoFieldsFragment = { startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean };

export type TSearchResultCampaignsFragment = { campaigns: { pageInfo: { startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ cursor: string, node: { description: string, photoUrl?: string | null, slug: string, title: string, receivedCents: number, goalCents?: number | null, state: string, category: string } }> } };

export type TSignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type TSignInMutation = { signIn: { oauthPath?: string | null, user?: { id: string } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TSignOutMutationVariables = Exact<{ [key: string]: never; }>;


export type TSignOutMutation = { signOut: { success: boolean } };

export type TContentReportMutationVariables = Exact<{
  type?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  captchaSolution?: InputMaybe<Scalars['String']['input']>;
}>;


export type TContentReportMutation = { contentReport: { messages?: Array<{ field: string, message: string }> | null } };

export type TCampaignDeleteMutationVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type TCampaignDeleteMutation = { campaignDelete: { success?: boolean | null, messages?: Array<{ field: string, message: string }> | null } };

export type TCampaignDenunciateMutationVariables = Exact<{
  slug: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  captchaSolution?: InputMaybe<Scalars['String']['input']>;
}>;


export type TCampaignDenunciateMutation = { campaignDenunciate: { messages?: Array<{ field: string, message: string }> | null } };

export type TCampaignEventMutationVariables = Exact<{
  slug: Scalars['String']['input'];
  event: Scalars['String']['input'];
}>;


export type TCampaignEventMutation = { campaignEvent: { campaign?: { id: string, state: string } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TCampaignInquireMutationVariables = Exact<{
  slug: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<Scalars['Boolean']['input']>;
  captchaSolution?: InputMaybe<Scalars['String']['input']>;
}>;


export type TCampaignInquireMutation = { campaignInquire: { messages?: Array<{ field: string, message: string }> | null } };

export type TRequestPaypalReferralMutationVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type TRequestPaypalReferralMutation = { requestPaypalReferral: { redirectUrl?: string | null, messages?: Array<{ message: string, field: string }> | null } };

export type TSendDonorReportMutationVariables = Exact<{
  slug: Scalars['String']['input'];
  year: Scalars['Int']['input'];
  month: Scalars['Int']['input'];
}>;


export type TSendDonorReportMutation = { sendDonorReport: { success?: boolean | null } };

export type TCampaignUpdateMutationVariables = Exact<{
  businessName?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryAlpha2?: InputMaybe<Scalars['String']['input']>;
  crop?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  feesConsent?: InputMaybe<Scalars['Boolean']['input']>;
  goal?: InputMaybe<Scalars['Int']['input']>;
  newSlug?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
  trackingVia?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
}>;


export type TCampaignUpdateMutation = { campaignUpdate: { campaign?: { id: string, category: string, crop?: string | null, description: string, goal?: number | null, goalCents?: number | null, needsRiskReview: boolean, photoOriginalUrl?: string | null, photoUrl?: string | null, slug: string, state: string, title: string, invoiceAddress?: { id: string, businessName?: string | null, city: string, countryAlpha2: string, firstName: string, lastName: string, street: string, vatId?: string | null, zipCode: string } | null, account: { id: string, anyPspEnabled: boolean, paypalEnabled: boolean, paypalMerchantStatus: string, stripeEnabled: boolean, stripeVerificationStatus: string, stripeAccount?: { id: string, accountNumberLastFour?: string | null, bankName?: string | null, businessType?: string | null, city?: string | null, countryAlpha2?: string | null, dobDay?: string | null, dobMonth?: string | null, dobYear?: string | null, documentProvided: boolean, fieldsNeeded: Array<string>, firstName?: string | null, lastName?: string | null, payoutsEnabled: boolean, street?: string | null, zipCode?: string | null } | null } } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TDonationEditMutationVariables = Exact<{
  comment?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  showUser?: InputMaybe<Scalars['Boolean']['input']>;
  deleteImage?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
}>;


export type TDonationEditMutation = { donationEdit: { privateDonor?: { id: string, comment?: string | null, imageUrl?: string | null, showUser: boolean } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TFinalizePaypalMutationVariables = Exact<{
  orderId: Scalars['String']['input'];
}>;


export type TFinalizePaypalMutation = { finalizePaypal: { paypalErrorKey?: string | null, redirectUrl?: string | null } };

export type TDonationMakeMutationVariables = Exact<{
  campaignId: Scalars['ID']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  amountCents?: InputMaybe<Scalars['Int']['input']>;
  codonationCents?: InputMaybe<Scalars['Int']['input']>;
  codonationEdited?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  captchaSolution?: InputMaybe<Scalars['String']['input']>;
  stripeClientSecret?: InputMaybe<Scalars['String']['input']>;
  stripeSourceId?: InputMaybe<Scalars['String']['input']>;
  subscribeToOrg?: InputMaybe<Scalars['Boolean']['input']>;
  tracking?: InputMaybe<TDonationTrackingInput>;
  receiptRequested?: InputMaybe<Scalars['Boolean']['input']>;
  dobDay?: InputMaybe<Scalars['String']['input']>;
  dobMonth?: InputMaybe<Scalars['String']['input']>;
  dobYear?: InputMaybe<Scalars['String']['input']>;
}>;


export type TDonationMakeMutation = { donationMake: { paypalOrderId?: string | null, redirectUrl?: string | null, donation?: { id: string } | null, messages?: Array<{ field: string, message: string }> | null, stripePaymentIntent?: { clientSecret: string, isCreditCard: boolean } | null } };

export type TRefundMutationVariables = Exact<{
  donationId: Scalars['ID']['input'];
}>;


export type TRefundMutation = { refund: { donation?: { id: string, refundTriggered: boolean } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TSendThankYouMessageMutationVariables = Exact<{
  donationId: Scalars['ID']['input'];
  thankYouMessage?: InputMaybe<Scalars['String']['input']>;
  activateResponseEmail?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type TSendThankYouMessageMutation = { sendThankYouMessage: { donation?: { id: string, privateDonor: { id: string, thankYouMessage?: string | null } } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TBrowserEventMutationVariables = Exact<{
  eventName?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
}>;


export type TBrowserEventMutation = { browserEvent: { success?: boolean | null } };

export type TNewsDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TNewsDeleteMutation = { newsDelete: { news?: { id: string, title: string, body: string } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TNewsMakeMutationVariables = Exact<{
  campaignId: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  notify?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type TNewsMakeMutation = { newsMake: { news?: { id: string, title: string, body: string, notify: boolean } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TNewsUnsubscribeMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type TNewsUnsubscribeMutation = { newsUnsubscribe: { success: boolean } };

export type TNewsUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
}>;


export type TNewsUpdateMutation = { newsUpdate: { news?: { id: string, title: string, body: string } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TStripeKycStartOnboardingMutationVariables = Exact<{
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
}>;


export type TStripeKycStartOnboardingMutation = { stripeKycStartOnboarding: { redirectUrl?: string | null, messages?: Array<{ message: string, field: string }> | null } };

export type TStripeKycSubmitIndividualDataMutationVariables = Exact<{
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  dobDay?: InputMaybe<Scalars['String']['input']>;
  dobMonth?: InputMaybe<Scalars['String']['input']>;
  dobYear?: InputMaybe<Scalars['String']['input']>;
  documentBack?: InputMaybe<Scalars['String']['input']>;
  documentFront?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  stripeConsent?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type TStripeKycSubmitIndividualDataMutation = { stripeKycSubmitIndividualData: { campaign?: { id: string, addressLocked: boolean, account: { id: string, anyPspEnabled: boolean, paypalEnabled: boolean, paypalMerchantStatus: string, stripeEnabled: boolean, stripeVerificationStatus: string, stripeAccount?: { id: string, accountNumberLastFour?: string | null, bankName?: string | null, businessType?: string | null, city?: string | null, countryAlpha2?: string | null, dobDay?: string | null, dobMonth?: string | null, dobYear?: string | null, documentProvided: boolean, fieldsNeeded: Array<string>, firstName?: string | null, lastName?: string | null, payoutsEnabled: boolean, street?: string | null, zipCode?: string | null } | null } } | null, messages?: Array<{ message: string, field: string }> | null } };

export type TChangePasswordMutationVariables = Exact<{
  password?: InputMaybe<Scalars['String']['input']>;
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
  currentPassword?: InputMaybe<Scalars['String']['input']>;
}>;


export type TChangePasswordMutation = { changePassword: { user?: { id: string } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TConfirmEmailMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']['input']>;
}>;


export type TConfirmEmailMutation = { confirmEmail: { user?: { id: string } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TUserDeleteMutationVariables = Exact<{ [key: string]: never; }>;


export type TUserDeleteMutation = { userDelete: { success?: boolean | null, messages?: Array<{ field: string, message: string }> | null } };

export type TPasswordResetMutationVariables = Exact<{
  token: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>;
}>;


export type TPasswordResetMutation = { passwordReset: { resetPassword?: boolean | null, messages?: Array<{ field: string, message: string }> | null } };

export type TRequestPasswordResetMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type TRequestPasswordResetMutation = { requestPasswordReset: { sentInstructions: boolean } };

export type TResendConfirmationMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type TResendConfirmationMutation = { resendConfirmation: { messages?: Array<{ field: string, message: string }> | null } };

export type TSignUpMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  captchaSolution?: InputMaybe<Scalars['String']['input']>;
}>;


export type TSignUpMutation = { signUp: { user?: { id: string } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TUserUpdateMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  crop?: InputMaybe<Scalars['String']['input']>;
}>;


export type TUserUpdateMutation = { userUpdate: { user?: { id: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null, avatarOriginalUrl?: string | null, crop?: string | null } | null, messages?: Array<{ field: string, message: string }> | null } };

export type TQuickinfosQueryVariables = Exact<{
  type: TQuickinfoType;
  recordId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type TQuickinfosQuery = { viewer?: { id: string, quickinfos: Array<{ title: string, url?: string | null, urlRisky?: boolean | null, detailsHtml?: string | null }> } | null };

export type TViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type TViewerQuery = { viewer?: { email: string, confirmed: boolean, name: string, id: string, firstName: string, lastName: string, nameLocked: boolean, avatarUrl?: string | null, avatarOriginalUrl?: string | null, crop?: string | null, deletable: boolean, hasInvoiceAddress: boolean, showQuickinfos: boolean, campaigns: { edges: Array<{ cursor: string, node: { id: string, state: string, slug: string, title: string } }> } } | null };

export type TCampaignQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type TCampaignQuery = { campaign: { id: string, state: string, category: string, city?: string | null, countryAlpha2?: string | null, countryName?: string | null, description: string, forceCaptcha: boolean, needsRiskReview: boolean, goal?: number | null, goalCents?: number | null, receivedCents: number, photoUrl?: string | null, createdAt: any, slug: string, newsCount: number, businessName?: string | null, title: string, account: { id: string, anyPspEnabled: boolean, paypalEnabled: boolean, paypalId?: string | null, paypalPending: boolean, stripeEnabled: boolean, stripePending: boolean, stripeHolderName?: string | null }, manager: { id: string, avatarUrl?: string | null, name: string, hasPseudonym?: boolean | null } } };

export type TCampaignDonationListQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TCampaignDonationListQuery = { campaign: { id: string, receivedCents: number, confirmedDonationCount: number, donations: { pageInfo: { startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ cursor: string, node: { id: string, amountCents: number, createdAt: any, confirmedAt?: any | null, comment?: string | null, imageUrl?: string | null, publicDonor: { id: string, firstName?: string | null } } }> } } };

export type TFeaturedCampaignListQueryVariables = Exact<{ [key: string]: never; }>;


export type TFeaturedCampaignListQuery = { search: { campaigns: { edges: Array<{ node: { id: string, description: string, goalCents?: number | null, photoUrl?: string | null, receivedCents: number, slug: string, state: string, title: string } }> } } };

export type TInvoiceInfosQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  period: Scalars['String']['input'];
}>;


export type TInvoiceInfosQuery = { viewer?: { id: string, invoiceInfos: Array<{ id: string, name: string }> } | null };

export type TCampaignListQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type TCampaignListQuery = { search: { totalCount: number, campaigns: { pageInfo: { startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ cursor: string, node: { description: string, photoUrl?: string | null, slug: string, title: string, receivedCents: number, goalCents?: number | null, state: string, category: string } }> } } };

export type TManagedCampaignQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  newsCursor?: InputMaybe<Scalars['String']['input']>;
  invoicesCursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type TManagedCampaignQuery = { viewer?: { id: string, firstName: string, lastName: string, nameLocked: boolean, campaign: { id: string, category: string, city?: string | null, deletable: boolean, description: string, goal?: number | null, addressLocked: boolean, photoUrl?: string | null, photoOriginalUrl?: string | null, crop?: string | null, slug: string, title: string, state: string, donorsToNotifyCount: number, needsRiskReview: boolean, news: { pageInfo: { startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ cursor: string, node: { id: string, createdAt: any, title: string, body: string } }> }, invoices: { pageInfo: { startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ cursor: string, node: { period: string, monthYear: string, year: string, corrected: boolean } }> }, donorReportReferencePeriods: Array<{ year: number, month: number }>, invoiceAddress?: { id: string, businessName?: string | null, city: string, countryAlpha2: string, firstName: string, lastName: string, street: string, vatId?: string | null, zipCode: string } | null, account: { id: string, anyPspEnabled: boolean, paypalEnabled: boolean, paypalMerchantStatus: string, stripeEnabled: boolean, stripeVerificationStatus: string, stripeAccount?: { id: string, accountNumberLastFour?: string | null, bankName?: string | null, businessType?: string | null, city?: string | null, countryAlpha2?: string | null, dobDay?: string | null, dobMonth?: string | null, dobYear?: string | null, documentProvided: boolean, fieldsNeeded: Array<string>, firstName?: string | null, lastName?: string | null, payoutsEnabled: boolean, street?: string | null, zipCode?: string | null } | null } } } | null };

export type TManagedCampaignsQueryVariables = Exact<{ [key: string]: never; }>;


export type TManagedCampaignsQuery = { viewer?: { id: string, campaigns: { edges: Array<{ node: { id: string, description: string, photoUrl?: string | null, slug: string, title: string, state: string, receivedCents: number, goalCents?: number | null } }> } } | null };

export type TOnboardingCampaignQueryVariables = Exact<{ [key: string]: never; }>;


export type TOnboardingCampaignQuery = { viewer?: { id: string, firstName: string, lastName: string, nameLocked: boolean, onboardingCampaign?: { id: string, category: string, city?: string | null, deletable: boolean, description: string, goal?: number | null, addressLocked: boolean, photoUrl?: string | null, photoOriginalUrl?: string | null, crop?: string | null, slug: string, title: string, state: string, donorsToNotifyCount: number, needsRiskReview: boolean, news: { pageInfo: { startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ cursor: string, node: { id: string, createdAt: any, title: string, body: string } }> }, invoices: { pageInfo: { startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ cursor: string, node: { period: string, monthYear: string, year: string, corrected: boolean } }> }, donorReportReferencePeriods: Array<{ year: number, month: number }>, invoiceAddress?: { id: string, businessName?: string | null, city: string, countryAlpha2: string, firstName: string, lastName: string, street: string, vatId?: string | null, zipCode: string } | null, account: { id: string, anyPspEnabled: boolean, paypalEnabled: boolean, paypalMerchantStatus: string, stripeEnabled: boolean, stripeVerificationStatus: string, stripeAccount?: { id: string, accountNumberLastFour?: string | null, bankName?: string | null, businessType?: string | null, city?: string | null, countryAlpha2?: string | null, dobDay?: string | null, dobMonth?: string | null, dobYear?: string | null, documentProvided: boolean, fieldsNeeded: Array<string>, firstName?: string | null, lastName?: string | null, payoutsEnabled: boolean, street?: string | null, zipCode?: string | null } | null } } | null } | null };

export type TCampaignReceivedCentsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type TCampaignReceivedCentsQuery = { campaign: { id: string, receivedCents: number } };

export type TCategoryListQueryVariables = Exact<{ [key: string]: never; }>;


export type TCategoryListQuery = { categories: Array<{ id: string, title: string, slug: string }> };

export type TCategoryQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type TCategoryQuery = { category: { id: string, cta: string, description: string, title: string, slug: string } };

export type TGermanCityQueryVariables = Exact<{
  zipCode: Scalars['String']['input'];
}>;


export type TGermanCityQuery = { city?: string | null };

export type TDonationQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type TDonationQuery = { donation: { id: string, amountCents: number, codonationCents: number, createdAt: any, state: string, notify: boolean, receiptRequested: boolean, paymentMethod?: string | null, subscribeToOrg: boolean, privateDonor: { id: string, comment?: string | null, firstName?: string | null, fullName?: string | null, imageUrl?: string | null, paymentMethod: string, showUser: boolean }, campaign: { id: string, slug: string, title: string, ownerName: string, goalCents?: number | null, receivedCents: number, category: string, photoUrl?: string | null } } };

export type TDonationStateQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type TDonationStateQuery = { donation: { id: string, state: string } };

export type TDonationListQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  state?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type TDonationListQuery = { viewer?: { id: string, campaign: { id: string, slug: string, title: string, receivedCents: number, receivedPaypalCents: number, receivedStripeCents: number, transactionFeeCents: number, manager: { id: string }, donations: { pageInfo: { startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ node: { id: string, createdAt: any, confirmedAt?: any | null, amountCents: number, state: string, refundable: boolean, refundTriggered: boolean, refundedAt?: any | null, privateDonor: { id: string, comment?: string | null, firstName?: string | null, fullName?: string | null, imageUrl?: string | null, paymentMethod: string, thankYouMessage?: string | null } } }> } } } | null };

export type TNewsItemQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TNewsItemQuery = { news: { notify: boolean, id: string, createdAt: any, title: string, body: string } };

export type TNewsItemsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
}>;


export type TNewsItemsQuery = { campaign: { id: string, news: { pageInfo: { startCursor?: string | null, endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges: Array<{ cursor: string, node: { id: string, createdAt: any, title: string, body: string } }> } } };

export const CampaignFieldsFragmentDoc = gql`
    fragment CampaignFields on Campaign {
  id
  category
  city
  deletable
  description
  goal
  addressLocked
  photoUrl(variant: "medium")
  photoOriginalUrl
  crop
  slug
  title
  state
  donorsToNotifyCount
  needsRiskReview
  donorReportReferencePeriods {
    year
    month
  }
}
    `;
export const InvoiceAddressFragmentDoc = gql`
    fragment InvoiceAddress on Campaign {
  invoiceAddress {
    id
    businessName
    city
    countryAlpha2
    firstName
    lastName
    street
    vatId
    zipCode
  }
}
    `;
export const ManagedAccountFragmentDoc = gql`
    fragment ManagedAccount on Campaign {
  account {
    id
    anyPspEnabled
    paypalEnabled
    paypalMerchantStatus
    stripeEnabled
    stripeVerificationStatus
    stripeAccount {
      id
      accountNumberLastFour
      bankName
      businessType
      city
      countryAlpha2
      dobDay
      dobMonth
      dobYear
      documentProvided
      fieldsNeeded
      firstName
      lastName
      payoutsEnabled
      street
      zipCode
    }
  }
}
    `;
export const NewsFieldsFragmentDoc = gql`
    fragment NewsFields on News {
  id
  createdAt
  title
  body
}
    `;
export const PageInfoFieldsFragmentDoc = gql`
    fragment PageInfoFields on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
    `;
export const SearchResultCampaignsFragmentDoc = gql`
    fragment SearchResultCampaigns on Search {
  campaigns(first: 8, q: $q, category: $category, after: $cursor) {
    pageInfo {
      ...PageInfoFields
    }
    edges {
      cursor
      node {
        description
        photoUrl(variant: "small")
        slug
        title
        receivedCents
        goalCents
        state
        category
      }
    }
  }
}
    `;
export const SignInDocument = gql`
    mutation SignIn($email: String!, $password: String!) {
  signIn(input: {email: $email, password: $password}) {
    user {
      id
    }
    oauthPath
    messages {
      field
      message
    }
  }
}
    `;
export type TSignInMutationFn = Apollo.MutationFunction<TSignInMutation, TSignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<TSignInMutation, TSignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TSignInMutation, TSignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<TSignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<TSignInMutation, TSignInMutationVariables>;
export const SignOutDocument = gql`
    mutation SignOut {
  signOut(input: {}) {
    success
  }
}
    `;
export type TSignOutMutationFn = Apollo.MutationFunction<TSignOutMutation, TSignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: Apollo.MutationHookOptions<TSignOutMutation, TSignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TSignOutMutation, TSignOutMutationVariables>(SignOutDocument, options);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<TSignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<TSignOutMutation, TSignOutMutationVariables>;
export const ContentReportDocument = gql`
    mutation ContentReport($type: String, $name: String, $email: String, $message: String, $captchaSolution: String) {
  contentReport(
    input: {type: $type, message: $message, name: $name, email: $email, captchaSolution: $captchaSolution}
  ) {
    messages {
      field
      message
    }
  }
}
    `;
export type TContentReportMutationFn = Apollo.MutationFunction<TContentReportMutation, TContentReportMutationVariables>;

/**
 * __useContentReportMutation__
 *
 * To run a mutation, you first call `useContentReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentReportMutation, { data, loading, error }] = useContentReportMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      message: // value for 'message'
 *      captchaSolution: // value for 'captchaSolution'
 *   },
 * });
 */
export function useContentReportMutation(baseOptions?: Apollo.MutationHookOptions<TContentReportMutation, TContentReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TContentReportMutation, TContentReportMutationVariables>(ContentReportDocument, options);
      }
export type ContentReportMutationHookResult = ReturnType<typeof useContentReportMutation>;
export type ContentReportMutationResult = Apollo.MutationResult<TContentReportMutation>;
export type ContentReportMutationOptions = Apollo.BaseMutationOptions<TContentReportMutation, TContentReportMutationVariables>;
export const CampaignDeleteDocument = gql`
    mutation CampaignDelete($slug: String!) {
  campaignDelete(input: {slug: $slug}) {
    success
    messages {
      field
      message
    }
  }
}
    `;
export type TCampaignDeleteMutationFn = Apollo.MutationFunction<TCampaignDeleteMutation, TCampaignDeleteMutationVariables>;

/**
 * __useCampaignDeleteMutation__
 *
 * To run a mutation, you first call `useCampaignDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDeleteMutation, { data, loading, error }] = useCampaignDeleteMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCampaignDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TCampaignDeleteMutation, TCampaignDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TCampaignDeleteMutation, TCampaignDeleteMutationVariables>(CampaignDeleteDocument, options);
      }
export type CampaignDeleteMutationHookResult = ReturnType<typeof useCampaignDeleteMutation>;
export type CampaignDeleteMutationResult = Apollo.MutationResult<TCampaignDeleteMutation>;
export type CampaignDeleteMutationOptions = Apollo.BaseMutationOptions<TCampaignDeleteMutation, TCampaignDeleteMutationVariables>;
export const CampaignDenunciateDocument = gql`
    mutation CampaignDenunciate($slug: String!, $type: String, $name: String, $email: String, $message: String, $captchaSolution: String) {
  campaignDenunciate(
    input: {slug: $slug, type: $type, message: $message, name: $name, email: $email, captchaSolution: $captchaSolution}
  ) {
    messages {
      field
      message
    }
  }
}
    `;
export type TCampaignDenunciateMutationFn = Apollo.MutationFunction<TCampaignDenunciateMutation, TCampaignDenunciateMutationVariables>;

/**
 * __useCampaignDenunciateMutation__
 *
 * To run a mutation, you first call `useCampaignDenunciateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDenunciateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDenunciateMutation, { data, loading, error }] = useCampaignDenunciateMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      message: // value for 'message'
 *      captchaSolution: // value for 'captchaSolution'
 *   },
 * });
 */
export function useCampaignDenunciateMutation(baseOptions?: Apollo.MutationHookOptions<TCampaignDenunciateMutation, TCampaignDenunciateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TCampaignDenunciateMutation, TCampaignDenunciateMutationVariables>(CampaignDenunciateDocument, options);
      }
export type CampaignDenunciateMutationHookResult = ReturnType<typeof useCampaignDenunciateMutation>;
export type CampaignDenunciateMutationResult = Apollo.MutationResult<TCampaignDenunciateMutation>;
export type CampaignDenunciateMutationOptions = Apollo.BaseMutationOptions<TCampaignDenunciateMutation, TCampaignDenunciateMutationVariables>;
export const CampaignEventDocument = gql`
    mutation CampaignEvent($slug: String!, $event: String!) {
  campaignEvent(input: {slug: $slug, event: $event}) {
    campaign {
      id
      state
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TCampaignEventMutationFn = Apollo.MutationFunction<TCampaignEventMutation, TCampaignEventMutationVariables>;

/**
 * __useCampaignEventMutation__
 *
 * To run a mutation, you first call `useCampaignEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignEventMutation, { data, loading, error }] = useCampaignEventMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useCampaignEventMutation(baseOptions?: Apollo.MutationHookOptions<TCampaignEventMutation, TCampaignEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TCampaignEventMutation, TCampaignEventMutationVariables>(CampaignEventDocument, options);
      }
export type CampaignEventMutationHookResult = ReturnType<typeof useCampaignEventMutation>;
export type CampaignEventMutationResult = Apollo.MutationResult<TCampaignEventMutation>;
export type CampaignEventMutationOptions = Apollo.BaseMutationOptions<TCampaignEventMutation, TCampaignEventMutationVariables>;
export const CampaignInquireDocument = gql`
    mutation CampaignInquire($slug: String!, $email: String, $message: String, $name: String, $permission: Boolean, $captchaSolution: String) {
  campaignInquire(
    input: {slug: $slug, email: $email, message: $message, name: $name, permission: $permission, captchaSolution: $captchaSolution}
  ) {
    messages {
      field
      message
    }
  }
}
    `;
export type TCampaignInquireMutationFn = Apollo.MutationFunction<TCampaignInquireMutation, TCampaignInquireMutationVariables>;

/**
 * __useCampaignInquireMutation__
 *
 * To run a mutation, you first call `useCampaignInquireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignInquireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignInquireMutation, { data, loading, error }] = useCampaignInquireMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      email: // value for 'email'
 *      message: // value for 'message'
 *      name: // value for 'name'
 *      permission: // value for 'permission'
 *      captchaSolution: // value for 'captchaSolution'
 *   },
 * });
 */
export function useCampaignInquireMutation(baseOptions?: Apollo.MutationHookOptions<TCampaignInquireMutation, TCampaignInquireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TCampaignInquireMutation, TCampaignInquireMutationVariables>(CampaignInquireDocument, options);
      }
export type CampaignInquireMutationHookResult = ReturnType<typeof useCampaignInquireMutation>;
export type CampaignInquireMutationResult = Apollo.MutationResult<TCampaignInquireMutation>;
export type CampaignInquireMutationOptions = Apollo.BaseMutationOptions<TCampaignInquireMutation, TCampaignInquireMutationVariables>;
export const RequestPaypalReferralDocument = gql`
    mutation RequestPaypalReferral($slug: String!) {
  requestPaypalReferral(input: {slug: $slug}) {
    redirectUrl
    messages {
      message
      field
    }
  }
}
    `;
export type TRequestPaypalReferralMutationFn = Apollo.MutationFunction<TRequestPaypalReferralMutation, TRequestPaypalReferralMutationVariables>;

/**
 * __useRequestPaypalReferralMutation__
 *
 * To run a mutation, you first call `useRequestPaypalReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPaypalReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPaypalReferralMutation, { data, loading, error }] = useRequestPaypalReferralMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useRequestPaypalReferralMutation(baseOptions?: Apollo.MutationHookOptions<TRequestPaypalReferralMutation, TRequestPaypalReferralMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TRequestPaypalReferralMutation, TRequestPaypalReferralMutationVariables>(RequestPaypalReferralDocument, options);
      }
export type RequestPaypalReferralMutationHookResult = ReturnType<typeof useRequestPaypalReferralMutation>;
export type RequestPaypalReferralMutationResult = Apollo.MutationResult<TRequestPaypalReferralMutation>;
export type RequestPaypalReferralMutationOptions = Apollo.BaseMutationOptions<TRequestPaypalReferralMutation, TRequestPaypalReferralMutationVariables>;
export const SendDonorReportDocument = gql`
    mutation SendDonorReport($slug: String!, $year: Int!, $month: Int!) {
  sendDonorReport(input: {slug: $slug, year: $year, month: $month}) {
    success
  }
}
    `;
export type TSendDonorReportMutationFn = Apollo.MutationFunction<TSendDonorReportMutation, TSendDonorReportMutationVariables>;

/**
 * __useSendDonorReportMutation__
 *
 * To run a mutation, you first call `useSendDonorReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDonorReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDonorReportMutation, { data, loading, error }] = useSendDonorReportMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *   },
 * });
 */
export function useSendDonorReportMutation(baseOptions?: Apollo.MutationHookOptions<TSendDonorReportMutation, TSendDonorReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TSendDonorReportMutation, TSendDonorReportMutationVariables>(SendDonorReportDocument, options);
      }
export type SendDonorReportMutationHookResult = ReturnType<typeof useSendDonorReportMutation>;
export type SendDonorReportMutationResult = Apollo.MutationResult<TSendDonorReportMutation>;
export type SendDonorReportMutationOptions = Apollo.BaseMutationOptions<TSendDonorReportMutation, TSendDonorReportMutationVariables>;
export const CampaignUpdateDocument = gql`
    mutation CampaignUpdate($businessName: String, $category: String, $city: String, $countryAlpha2: String, $crop: String, $description: String, $feesConsent: Boolean, $goal: Int, $newSlug: String, $photo: String, $slug: String, $street: String, $submit: Boolean, $title: String, $zipCode: String, $trackingVia: String, $vatId: String) {
  campaignUpdate(
    input: {category: $category, crop: $crop, description: $description, feesConsent: $feesConsent, goal: $goal, newSlug: $newSlug, photo: $photo, slug: $slug, submit: $submit, title: $title, invoiceAddress: {businessName: $businessName, city: $city, countryAlpha2: $countryAlpha2, street: $street, vatId: $vatId, zipCode: $zipCode}, trackingVia: $trackingVia}
  ) {
    campaign {
      id
      category
      crop
      description
      goal
      goalCents
      needsRiskReview
      photoOriginalUrl
      photoUrl(variant: "medium")
      slug
      state
      title
      ...InvoiceAddress
      ...ManagedAccount
    }
    messages {
      field
      message
    }
  }
}
    ${InvoiceAddressFragmentDoc}
${ManagedAccountFragmentDoc}`;
export type TCampaignUpdateMutationFn = Apollo.MutationFunction<TCampaignUpdateMutation, TCampaignUpdateMutationVariables>;

/**
 * __useCampaignUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignUpdateMutation, { data, loading, error }] = useCampaignUpdateMutation({
 *   variables: {
 *      businessName: // value for 'businessName'
 *      category: // value for 'category'
 *      city: // value for 'city'
 *      countryAlpha2: // value for 'countryAlpha2'
 *      crop: // value for 'crop'
 *      description: // value for 'description'
 *      feesConsent: // value for 'feesConsent'
 *      goal: // value for 'goal'
 *      newSlug: // value for 'newSlug'
 *      photo: // value for 'photo'
 *      slug: // value for 'slug'
 *      street: // value for 'street'
 *      submit: // value for 'submit'
 *      title: // value for 'title'
 *      zipCode: // value for 'zipCode'
 *      trackingVia: // value for 'trackingVia'
 *      vatId: // value for 'vatId'
 *   },
 * });
 */
export function useCampaignUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TCampaignUpdateMutation, TCampaignUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TCampaignUpdateMutation, TCampaignUpdateMutationVariables>(CampaignUpdateDocument, options);
      }
export type CampaignUpdateMutationHookResult = ReturnType<typeof useCampaignUpdateMutation>;
export type CampaignUpdateMutationResult = Apollo.MutationResult<TCampaignUpdateMutation>;
export type CampaignUpdateMutationOptions = Apollo.BaseMutationOptions<TCampaignUpdateMutation, TCampaignUpdateMutationVariables>;
export const DonationEditDocument = gql`
    mutation DonationEdit($comment: String, $image: String, $showUser: Boolean, $deleteImage: Boolean, $token: String!) {
  donationEdit(
    input: {comment: $comment, image: $image, deleteImage: $deleteImage, showUser: $showUser, token: $token}
  ) {
    privateDonor {
      id
      comment
      imageUrl
      showUser
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TDonationEditMutationFn = Apollo.MutationFunction<TDonationEditMutation, TDonationEditMutationVariables>;

/**
 * __useDonationEditMutation__
 *
 * To run a mutation, you first call `useDonationEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonationEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donationEditMutation, { data, loading, error }] = useDonationEditMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      image: // value for 'image'
 *      showUser: // value for 'showUser'
 *      deleteImage: // value for 'deleteImage'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDonationEditMutation(baseOptions?: Apollo.MutationHookOptions<TDonationEditMutation, TDonationEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TDonationEditMutation, TDonationEditMutationVariables>(DonationEditDocument, options);
      }
export type DonationEditMutationHookResult = ReturnType<typeof useDonationEditMutation>;
export type DonationEditMutationResult = Apollo.MutationResult<TDonationEditMutation>;
export type DonationEditMutationOptions = Apollo.BaseMutationOptions<TDonationEditMutation, TDonationEditMutationVariables>;
export const FinalizePaypalDocument = gql`
    mutation FinalizePaypal($orderId: String!) {
  finalizePaypal(input: {orderId: $orderId}) {
    paypalErrorKey
    redirectUrl
  }
}
    `;
export type TFinalizePaypalMutationFn = Apollo.MutationFunction<TFinalizePaypalMutation, TFinalizePaypalMutationVariables>;

/**
 * __useFinalizePaypalMutation__
 *
 * To run a mutation, you first call `useFinalizePaypalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizePaypalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizePaypalMutation, { data, loading, error }] = useFinalizePaypalMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useFinalizePaypalMutation(baseOptions?: Apollo.MutationHookOptions<TFinalizePaypalMutation, TFinalizePaypalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TFinalizePaypalMutation, TFinalizePaypalMutationVariables>(FinalizePaypalDocument, options);
      }
export type FinalizePaypalMutationHookResult = ReturnType<typeof useFinalizePaypalMutation>;
export type FinalizePaypalMutationResult = Apollo.MutationResult<TFinalizePaypalMutation>;
export type FinalizePaypalMutationOptions = Apollo.BaseMutationOptions<TFinalizePaypalMutation, TFinalizePaypalMutationVariables>;
export const DonationMakeDocument = gql`
    mutation DonationMake($campaignId: ID!, $firstName: String, $lastName: String, $email: String, $amountCents: Int, $codonationCents: Int, $codonationEdited: Boolean, $paymentMethod: String, $captchaSolution: String, $stripeClientSecret: String, $stripeSourceId: String, $subscribeToOrg: Boolean, $tracking: DonationTrackingInput, $receiptRequested: Boolean, $dobDay: String, $dobMonth: String, $dobYear: String) {
  donationMake(
    input: {campaignId: $campaignId, firstName: $firstName, lastName: $lastName, email: $email, amountCents: $amountCents, codonationCents: $codonationCents, codonationEdited: $codonationEdited, paymentMethod: $paymentMethod, captchaSolution: $captchaSolution, stripeClientSecret: $stripeClientSecret, stripeSourceId: $stripeSourceId, subscribeToOrg: $subscribeToOrg, tracking: $tracking, receiptRequested: $receiptRequested, dobDay: $dobDay, dobMonth: $dobMonth, dobYear: $dobYear}
  ) {
    donation {
      id
    }
    messages {
      field
      message
    }
    paypalOrderId
    redirectUrl
    stripePaymentIntent {
      clientSecret
      isCreditCard
    }
  }
}
    `;
export type TDonationMakeMutationFn = Apollo.MutationFunction<TDonationMakeMutation, TDonationMakeMutationVariables>;

/**
 * __useDonationMakeMutation__
 *
 * To run a mutation, you first call `useDonationMakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonationMakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donationMakeMutation, { data, loading, error }] = useDonationMakeMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      amountCents: // value for 'amountCents'
 *      codonationCents: // value for 'codonationCents'
 *      codonationEdited: // value for 'codonationEdited'
 *      paymentMethod: // value for 'paymentMethod'
 *      captchaSolution: // value for 'captchaSolution'
 *      stripeClientSecret: // value for 'stripeClientSecret'
 *      stripeSourceId: // value for 'stripeSourceId'
 *      subscribeToOrg: // value for 'subscribeToOrg'
 *      tracking: // value for 'tracking'
 *      receiptRequested: // value for 'receiptRequested'
 *      dobDay: // value for 'dobDay'
 *      dobMonth: // value for 'dobMonth'
 *      dobYear: // value for 'dobYear'
 *   },
 * });
 */
export function useDonationMakeMutation(baseOptions?: Apollo.MutationHookOptions<TDonationMakeMutation, TDonationMakeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TDonationMakeMutation, TDonationMakeMutationVariables>(DonationMakeDocument, options);
      }
export type DonationMakeMutationHookResult = ReturnType<typeof useDonationMakeMutation>;
export type DonationMakeMutationResult = Apollo.MutationResult<TDonationMakeMutation>;
export type DonationMakeMutationOptions = Apollo.BaseMutationOptions<TDonationMakeMutation, TDonationMakeMutationVariables>;
export const RefundDocument = gql`
    mutation Refund($donationId: ID!) {
  refund(input: {donationId: $donationId}) {
    donation {
      id
      refundTriggered
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TRefundMutationFn = Apollo.MutationFunction<TRefundMutation, TRefundMutationVariables>;

/**
 * __useRefundMutation__
 *
 * To run a mutation, you first call `useRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundMutation, { data, loading, error }] = useRefundMutation({
 *   variables: {
 *      donationId: // value for 'donationId'
 *   },
 * });
 */
export function useRefundMutation(baseOptions?: Apollo.MutationHookOptions<TRefundMutation, TRefundMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TRefundMutation, TRefundMutationVariables>(RefundDocument, options);
      }
export type RefundMutationHookResult = ReturnType<typeof useRefundMutation>;
export type RefundMutationResult = Apollo.MutationResult<TRefundMutation>;
export type RefundMutationOptions = Apollo.BaseMutationOptions<TRefundMutation, TRefundMutationVariables>;
export const SendThankYouMessageDocument = gql`
    mutation SendThankYouMessage($donationId: ID!, $thankYouMessage: String, $activateResponseEmail: Boolean) {
  sendThankYouMessage(
    input: {donationId: $donationId, thankYouMessage: $thankYouMessage, activateResponseEmail: $activateResponseEmail}
  ) {
    donation {
      id
      privateDonor {
        id
        thankYouMessage
      }
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TSendThankYouMessageMutationFn = Apollo.MutationFunction<TSendThankYouMessageMutation, TSendThankYouMessageMutationVariables>;

/**
 * __useSendThankYouMessageMutation__
 *
 * To run a mutation, you first call `useSendThankYouMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendThankYouMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendThankYouMessageMutation, { data, loading, error }] = useSendThankYouMessageMutation({
 *   variables: {
 *      donationId: // value for 'donationId'
 *      thankYouMessage: // value for 'thankYouMessage'
 *      activateResponseEmail: // value for 'activateResponseEmail'
 *   },
 * });
 */
export function useSendThankYouMessageMutation(baseOptions?: Apollo.MutationHookOptions<TSendThankYouMessageMutation, TSendThankYouMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TSendThankYouMessageMutation, TSendThankYouMessageMutationVariables>(SendThankYouMessageDocument, options);
      }
export type SendThankYouMessageMutationHookResult = ReturnType<typeof useSendThankYouMessageMutation>;
export type SendThankYouMessageMutationResult = Apollo.MutationResult<TSendThankYouMessageMutation>;
export type SendThankYouMessageMutationOptions = Apollo.BaseMutationOptions<TSendThankYouMessageMutation, TSendThankYouMessageMutationVariables>;
export const BrowserEventDocument = gql`
    mutation BrowserEvent($eventName: String, $deviceType: String) {
  browserEvent(input: {eventName: $eventName, deviceType: $deviceType}) {
    success
  }
}
    `;
export type TBrowserEventMutationFn = Apollo.MutationFunction<TBrowserEventMutation, TBrowserEventMutationVariables>;

/**
 * __useBrowserEventMutation__
 *
 * To run a mutation, you first call `useBrowserEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrowserEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [browserEventMutation, { data, loading, error }] = useBrowserEventMutation({
 *   variables: {
 *      eventName: // value for 'eventName'
 *      deviceType: // value for 'deviceType'
 *   },
 * });
 */
export function useBrowserEventMutation(baseOptions?: Apollo.MutationHookOptions<TBrowserEventMutation, TBrowserEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TBrowserEventMutation, TBrowserEventMutationVariables>(BrowserEventDocument, options);
      }
export type BrowserEventMutationHookResult = ReturnType<typeof useBrowserEventMutation>;
export type BrowserEventMutationResult = Apollo.MutationResult<TBrowserEventMutation>;
export type BrowserEventMutationOptions = Apollo.BaseMutationOptions<TBrowserEventMutation, TBrowserEventMutationVariables>;
export const NewsDeleteDocument = gql`
    mutation NewsDelete($id: ID!) {
  newsDelete(input: {id: $id}) {
    news {
      id
      title
      body
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TNewsDeleteMutationFn = Apollo.MutationFunction<TNewsDeleteMutation, TNewsDeleteMutationVariables>;

/**
 * __useNewsDeleteMutation__
 *
 * To run a mutation, you first call `useNewsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsDeleteMutation, { data, loading, error }] = useNewsDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewsDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TNewsDeleteMutation, TNewsDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TNewsDeleteMutation, TNewsDeleteMutationVariables>(NewsDeleteDocument, options);
      }
export type NewsDeleteMutationHookResult = ReturnType<typeof useNewsDeleteMutation>;
export type NewsDeleteMutationResult = Apollo.MutationResult<TNewsDeleteMutation>;
export type NewsDeleteMutationOptions = Apollo.BaseMutationOptions<TNewsDeleteMutation, TNewsDeleteMutationVariables>;
export const NewsMakeDocument = gql`
    mutation NewsMake($campaignId: ID!, $title: String, $body: String, $notify: Boolean) {
  newsMake(
    input: {campaignId: $campaignId, title: $title, body: $body, notify: $notify}
  ) {
    news {
      id
      title
      body
      notify
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TNewsMakeMutationFn = Apollo.MutationFunction<TNewsMakeMutation, TNewsMakeMutationVariables>;

/**
 * __useNewsMakeMutation__
 *
 * To run a mutation, you first call `useNewsMakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsMakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsMakeMutation, { data, loading, error }] = useNewsMakeMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      notify: // value for 'notify'
 *   },
 * });
 */
export function useNewsMakeMutation(baseOptions?: Apollo.MutationHookOptions<TNewsMakeMutation, TNewsMakeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TNewsMakeMutation, TNewsMakeMutationVariables>(NewsMakeDocument, options);
      }
export type NewsMakeMutationHookResult = ReturnType<typeof useNewsMakeMutation>;
export type NewsMakeMutationResult = Apollo.MutationResult<TNewsMakeMutation>;
export type NewsMakeMutationOptions = Apollo.BaseMutationOptions<TNewsMakeMutation, TNewsMakeMutationVariables>;
export const NewsUnsubscribeDocument = gql`
    mutation NewsUnsubscribe($email: String!) {
  newsUnsubscribe(input: {email: $email}) {
    success
  }
}
    `;
export type TNewsUnsubscribeMutationFn = Apollo.MutationFunction<TNewsUnsubscribeMutation, TNewsUnsubscribeMutationVariables>;

/**
 * __useNewsUnsubscribeMutation__
 *
 * To run a mutation, you first call `useNewsUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsUnsubscribeMutation, { data, loading, error }] = useNewsUnsubscribeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useNewsUnsubscribeMutation(baseOptions?: Apollo.MutationHookOptions<TNewsUnsubscribeMutation, TNewsUnsubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TNewsUnsubscribeMutation, TNewsUnsubscribeMutationVariables>(NewsUnsubscribeDocument, options);
      }
export type NewsUnsubscribeMutationHookResult = ReturnType<typeof useNewsUnsubscribeMutation>;
export type NewsUnsubscribeMutationResult = Apollo.MutationResult<TNewsUnsubscribeMutation>;
export type NewsUnsubscribeMutationOptions = Apollo.BaseMutationOptions<TNewsUnsubscribeMutation, TNewsUnsubscribeMutationVariables>;
export const NewsUpdateDocument = gql`
    mutation NewsUpdate($id: ID!, $title: String, $body: String) {
  newsUpdate(input: {id: $id, title: $title, body: $body}) {
    news {
      id
      title
      body
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TNewsUpdateMutationFn = Apollo.MutationFunction<TNewsUpdateMutation, TNewsUpdateMutationVariables>;

/**
 * __useNewsUpdateMutation__
 *
 * To run a mutation, you first call `useNewsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsUpdateMutation, { data, loading, error }] = useNewsUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useNewsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TNewsUpdateMutation, TNewsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TNewsUpdateMutation, TNewsUpdateMutationVariables>(NewsUpdateDocument, options);
      }
export type NewsUpdateMutationHookResult = ReturnType<typeof useNewsUpdateMutation>;
export type NewsUpdateMutationResult = Apollo.MutationResult<TNewsUpdateMutation>;
export type NewsUpdateMutationOptions = Apollo.BaseMutationOptions<TNewsUpdateMutation, TNewsUpdateMutationVariables>;
export const StripeKycStartOnboardingDocument = gql`
    mutation StripeKYCStartOnboarding($accountNumber: String, $slug: String) {
  stripeKycStartOnboarding(input: {accountNumber: $accountNumber, slug: $slug}) {
    redirectUrl
    messages {
      message
      field
    }
  }
}
    `;
export type TStripeKycStartOnboardingMutationFn = Apollo.MutationFunction<TStripeKycStartOnboardingMutation, TStripeKycStartOnboardingMutationVariables>;

/**
 * __useStripeKycStartOnboardingMutation__
 *
 * To run a mutation, you first call `useStripeKycStartOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeKycStartOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeKycStartOnboardingMutation, { data, loading, error }] = useStripeKycStartOnboardingMutation({
 *   variables: {
 *      accountNumber: // value for 'accountNumber'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useStripeKycStartOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<TStripeKycStartOnboardingMutation, TStripeKycStartOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TStripeKycStartOnboardingMutation, TStripeKycStartOnboardingMutationVariables>(StripeKycStartOnboardingDocument, options);
      }
export type StripeKycStartOnboardingMutationHookResult = ReturnType<typeof useStripeKycStartOnboardingMutation>;
export type StripeKycStartOnboardingMutationResult = Apollo.MutationResult<TStripeKycStartOnboardingMutation>;
export type StripeKycStartOnboardingMutationOptions = Apollo.BaseMutationOptions<TStripeKycStartOnboardingMutation, TStripeKycStartOnboardingMutationVariables>;
export const StripeKycSubmitIndividualDataDocument = gql`
    mutation StripeKYCSubmitIndividualData($accountNumber: String, $dobDay: String, $dobMonth: String, $dobYear: String, $documentBack: String, $documentFront: String, $slug: String, $stripeConsent: Boolean) {
  stripeKycSubmitIndividualData(
    input: {accountNumber: $accountNumber, dobDay: $dobDay, dobMonth: $dobMonth, dobYear: $dobYear, documentBack: $documentBack, documentFront: $documentFront, slug: $slug, stripeConsent: $stripeConsent}
  ) {
    campaign {
      id
      addressLocked
      ...ManagedAccount
    }
    messages {
      message
      field
    }
  }
}
    ${ManagedAccountFragmentDoc}`;
export type TStripeKycSubmitIndividualDataMutationFn = Apollo.MutationFunction<TStripeKycSubmitIndividualDataMutation, TStripeKycSubmitIndividualDataMutationVariables>;

/**
 * __useStripeKycSubmitIndividualDataMutation__
 *
 * To run a mutation, you first call `useStripeKycSubmitIndividualDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeKycSubmitIndividualDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeKycSubmitIndividualDataMutation, { data, loading, error }] = useStripeKycSubmitIndividualDataMutation({
 *   variables: {
 *      accountNumber: // value for 'accountNumber'
 *      dobDay: // value for 'dobDay'
 *      dobMonth: // value for 'dobMonth'
 *      dobYear: // value for 'dobYear'
 *      documentBack: // value for 'documentBack'
 *      documentFront: // value for 'documentFront'
 *      slug: // value for 'slug'
 *      stripeConsent: // value for 'stripeConsent'
 *   },
 * });
 */
export function useStripeKycSubmitIndividualDataMutation(baseOptions?: Apollo.MutationHookOptions<TStripeKycSubmitIndividualDataMutation, TStripeKycSubmitIndividualDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TStripeKycSubmitIndividualDataMutation, TStripeKycSubmitIndividualDataMutationVariables>(StripeKycSubmitIndividualDataDocument, options);
      }
export type StripeKycSubmitIndividualDataMutationHookResult = ReturnType<typeof useStripeKycSubmitIndividualDataMutation>;
export type StripeKycSubmitIndividualDataMutationResult = Apollo.MutationResult<TStripeKycSubmitIndividualDataMutation>;
export type StripeKycSubmitIndividualDataMutationOptions = Apollo.BaseMutationOptions<TStripeKycSubmitIndividualDataMutation, TStripeKycSubmitIndividualDataMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($password: String, $passwordConfirmation: String, $currentPassword: String) {
  changePassword(
    input: {password: $password, passwordConfirmation: $passwordConfirmation, currentPassword: $currentPassword}
  ) {
    user {
      id
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TChangePasswordMutationFn = Apollo.MutationFunction<TChangePasswordMutation, TChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<TChangePasswordMutation, TChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TChangePasswordMutation, TChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<TChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<TChangePasswordMutation, TChangePasswordMutationVariables>;
export const ConfirmEmailDocument = gql`
    mutation ConfirmEmail($token: String) {
  confirmEmail(input: {token: $token}) {
    user {
      id
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TConfirmEmailMutationFn = Apollo.MutationFunction<TConfirmEmailMutation, TConfirmEmailMutationVariables>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<TConfirmEmailMutation, TConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TConfirmEmailMutation, TConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<TConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<TConfirmEmailMutation, TConfirmEmailMutationVariables>;
export const UserDeleteDocument = gql`
    mutation UserDelete {
  userDelete(input: {}) {
    success
    messages {
      field
      message
    }
  }
}
    `;
export type TUserDeleteMutationFn = Apollo.MutationFunction<TUserDeleteMutation, TUserDeleteMutationVariables>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserDeleteMutation(baseOptions?: Apollo.MutationHookOptions<TUserDeleteMutation, TUserDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TUserDeleteMutation, TUserDeleteMutationVariables>(UserDeleteDocument, options);
      }
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<TUserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<TUserDeleteMutation, TUserDeleteMutationVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($token: String!, $password: String, $passwordConfirmation: String) {
  passwordReset(
    input: {token: $token, password: $password, passwordConfirmation: $passwordConfirmation}
  ) {
    resetPassword
    messages {
      field
      message
    }
  }
}
    `;
export type TPasswordResetMutationFn = Apollo.MutationFunction<TPasswordResetMutation, TPasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<TPasswordResetMutation, TPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TPasswordResetMutation, TPasswordResetMutationVariables>(PasswordResetDocument, options);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<TPasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<TPasswordResetMutation, TPasswordResetMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($email: String) {
  requestPasswordReset(input: {email: $email}) {
    sentInstructions
  }
}
    `;
export type TRequestPasswordResetMutationFn = Apollo.MutationFunction<TRequestPasswordResetMutation, TRequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<TRequestPasswordResetMutation, TRequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TRequestPasswordResetMutation, TRequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<TRequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<TRequestPasswordResetMutation, TRequestPasswordResetMutationVariables>;
export const ResendConfirmationDocument = gql`
    mutation ResendConfirmation($email: String) {
  resendConfirmation(input: {email: $email}) {
    messages {
      field
      message
    }
  }
}
    `;
export type TResendConfirmationMutationFn = Apollo.MutationFunction<TResendConfirmationMutation, TResendConfirmationMutationVariables>;

/**
 * __useResendConfirmationMutation__
 *
 * To run a mutation, you first call `useResendConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationMutation, { data, loading, error }] = useResendConfirmationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<TResendConfirmationMutation, TResendConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TResendConfirmationMutation, TResendConfirmationMutationVariables>(ResendConfirmationDocument, options);
      }
export type ResendConfirmationMutationHookResult = ReturnType<typeof useResendConfirmationMutation>;
export type ResendConfirmationMutationResult = Apollo.MutationResult<TResendConfirmationMutation>;
export type ResendConfirmationMutationOptions = Apollo.BaseMutationOptions<TResendConfirmationMutation, TResendConfirmationMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($firstName: String, $lastName: String, $email: String, $password: String, $captchaSolution: String) {
  signUp(
    input: {firstName: $firstName, lastName: $lastName, email: $email, password: $password, captchaSolution: $captchaSolution}
  ) {
    user {
      id
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TSignUpMutationFn = Apollo.MutationFunction<TSignUpMutation, TSignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      captchaSolution: // value for 'captchaSolution'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<TSignUpMutation, TSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TSignUpMutation, TSignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<TSignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<TSignUpMutation, TSignUpMutationVariables>;
export const UserUpdateDocument = gql`
    mutation UserUpdate($firstName: String, $lastName: String, $email: String, $avatar: String, $crop: String) {
  userUpdate(
    input: {firstName: $firstName, lastName: $lastName, email: $email, avatar: $avatar, crop: $crop}
  ) {
    user {
      id
      firstName
      lastName
      email
      avatarUrl
      avatarOriginalUrl
      crop
    }
    messages {
      field
      message
    }
  }
}
    `;
export type TUserUpdateMutationFn = Apollo.MutationFunction<TUserUpdateMutation, TUserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      avatar: // value for 'avatar'
 *      crop: // value for 'crop'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TUserUpdateMutation, TUserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TUserUpdateMutation, TUserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<TUserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<TUserUpdateMutation, TUserUpdateMutationVariables>;
export const QuickinfosDocument = gql`
    query Quickinfos($type: QuickinfoType!, $recordId: ID) {
  viewer {
    id
    quickinfos(type: $type, recordId: $recordId) {
      title
      url
      urlRisky
      detailsHtml
    }
  }
}
    `;

/**
 * __useQuickinfosQuery__
 *
 * To run a query within a React component, call `useQuickinfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickinfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickinfosQuery({
 *   variables: {
 *      type: // value for 'type'
 *      recordId: // value for 'recordId'
 *   },
 * });
 */
export function useQuickinfosQuery(baseOptions: Apollo.QueryHookOptions<TQuickinfosQuery, TQuickinfosQueryVariables> & ({ variables: TQuickinfosQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TQuickinfosQuery, TQuickinfosQueryVariables>(QuickinfosDocument, options);
      }
export function useQuickinfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TQuickinfosQuery, TQuickinfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TQuickinfosQuery, TQuickinfosQueryVariables>(QuickinfosDocument, options);
        }
export function useQuickinfosSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TQuickinfosQuery, TQuickinfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TQuickinfosQuery, TQuickinfosQueryVariables>(QuickinfosDocument, options);
        }
export type QuickinfosQueryHookResult = ReturnType<typeof useQuickinfosQuery>;
export type QuickinfosLazyQueryHookResult = ReturnType<typeof useQuickinfosLazyQuery>;
export type QuickinfosSuspenseQueryHookResult = ReturnType<typeof useQuickinfosSuspenseQuery>;
export type QuickinfosQueryResult = Apollo.QueryResult<TQuickinfosQuery, TQuickinfosQueryVariables>;
export const ViewerDocument = gql`
    query Viewer {
  viewer {
    email
    confirmed
    name
    id
    firstName
    lastName
    nameLocked
    avatarUrl
    avatarOriginalUrl
    crop
    deletable
    hasInvoiceAddress
    showQuickinfos
    campaigns(first: 3) {
      edges {
        cursor
        node {
          id
          state
          slug
          title
        }
      }
    }
  }
}
    `;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: Apollo.QueryHookOptions<TViewerQuery, TViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TViewerQuery, TViewerQueryVariables>(ViewerDocument, options);
      }
export function useViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TViewerQuery, TViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TViewerQuery, TViewerQueryVariables>(ViewerDocument, options);
        }
export function useViewerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TViewerQuery, TViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TViewerQuery, TViewerQueryVariables>(ViewerDocument, options);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerSuspenseQueryHookResult = ReturnType<typeof useViewerSuspenseQuery>;
export type ViewerQueryResult = Apollo.QueryResult<TViewerQuery, TViewerQueryVariables>;
export const CampaignDocument = gql`
    query Campaign($slug: String!) {
  campaign(slug: $slug) {
    id
    state
    category
    city
    countryAlpha2
    countryName
    description
    forceCaptcha
    needsRiskReview
    goal
    goalCents
    receivedCents
    photoUrl(variant: "medium")
    createdAt
    slug
    newsCount
    businessName
    title
    account {
      id
      anyPspEnabled
      paypalEnabled
      paypalId
      paypalPending
      stripeEnabled
      stripePending
      stripeHolderName
    }
    manager {
      id
      avatarUrl
      name
      hasPseudonym
    }
  }
}
    `;

/**
 * __useCampaignQuery__
 *
 * To run a query within a React component, call `useCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCampaignQuery(baseOptions: Apollo.QueryHookOptions<TCampaignQuery, TCampaignQueryVariables> & ({ variables: TCampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TCampaignQuery, TCampaignQueryVariables>(CampaignDocument, options);
      }
export function useCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TCampaignQuery, TCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TCampaignQuery, TCampaignQueryVariables>(CampaignDocument, options);
        }
export function useCampaignSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TCampaignQuery, TCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TCampaignQuery, TCampaignQueryVariables>(CampaignDocument, options);
        }
export type CampaignQueryHookResult = ReturnType<typeof useCampaignQuery>;
export type CampaignLazyQueryHookResult = ReturnType<typeof useCampaignLazyQuery>;
export type CampaignSuspenseQueryHookResult = ReturnType<typeof useCampaignSuspenseQuery>;
export type CampaignQueryResult = Apollo.QueryResult<TCampaignQuery, TCampaignQueryVariables>;
export const CampaignDonationListDocument = gql`
    query CampaignDonationList($slug: String!, $cursor: String, $first: Int = 4) {
  campaign(slug: $slug) {
    id
    receivedCents
    confirmedDonationCount
    donations(state: "confirmed", first: $first, after: $cursor) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          id
          amountCents
          createdAt
          confirmedAt
          comment
          imageUrl
          publicDonor {
            id
            firstName
          }
        }
      }
    }
  }
}
    ${PageInfoFieldsFragmentDoc}`;

/**
 * __useCampaignDonationListQuery__
 *
 * To run a query within a React component, call `useCampaignDonationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDonationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDonationListQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      cursor: // value for 'cursor'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCampaignDonationListQuery(baseOptions: Apollo.QueryHookOptions<TCampaignDonationListQuery, TCampaignDonationListQueryVariables> & ({ variables: TCampaignDonationListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TCampaignDonationListQuery, TCampaignDonationListQueryVariables>(CampaignDonationListDocument, options);
      }
export function useCampaignDonationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TCampaignDonationListQuery, TCampaignDonationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TCampaignDonationListQuery, TCampaignDonationListQueryVariables>(CampaignDonationListDocument, options);
        }
export function useCampaignDonationListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TCampaignDonationListQuery, TCampaignDonationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TCampaignDonationListQuery, TCampaignDonationListQueryVariables>(CampaignDonationListDocument, options);
        }
export type CampaignDonationListQueryHookResult = ReturnType<typeof useCampaignDonationListQuery>;
export type CampaignDonationListLazyQueryHookResult = ReturnType<typeof useCampaignDonationListLazyQuery>;
export type CampaignDonationListSuspenseQueryHookResult = ReturnType<typeof useCampaignDonationListSuspenseQuery>;
export type CampaignDonationListQueryResult = Apollo.QueryResult<TCampaignDonationListQuery, TCampaignDonationListQueryVariables>;
export const FeaturedCampaignListDocument = gql`
    query FeaturedCampaignList {
  search {
    campaigns(featured: true) {
      edges {
        node {
          id
          description
          goalCents
          photoUrl(variant: "small")
          receivedCents
          slug
          state
          title
        }
      }
    }
  }
}
    `;

/**
 * __useFeaturedCampaignListQuery__
 *
 * To run a query within a React component, call `useFeaturedCampaignListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedCampaignListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedCampaignListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedCampaignListQuery(baseOptions?: Apollo.QueryHookOptions<TFeaturedCampaignListQuery, TFeaturedCampaignListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TFeaturedCampaignListQuery, TFeaturedCampaignListQueryVariables>(FeaturedCampaignListDocument, options);
      }
export function useFeaturedCampaignListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TFeaturedCampaignListQuery, TFeaturedCampaignListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TFeaturedCampaignListQuery, TFeaturedCampaignListQueryVariables>(FeaturedCampaignListDocument, options);
        }
export function useFeaturedCampaignListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TFeaturedCampaignListQuery, TFeaturedCampaignListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TFeaturedCampaignListQuery, TFeaturedCampaignListQueryVariables>(FeaturedCampaignListDocument, options);
        }
export type FeaturedCampaignListQueryHookResult = ReturnType<typeof useFeaturedCampaignListQuery>;
export type FeaturedCampaignListLazyQueryHookResult = ReturnType<typeof useFeaturedCampaignListLazyQuery>;
export type FeaturedCampaignListSuspenseQueryHookResult = ReturnType<typeof useFeaturedCampaignListSuspenseQuery>;
export type FeaturedCampaignListQueryResult = Apollo.QueryResult<TFeaturedCampaignListQuery, TFeaturedCampaignListQueryVariables>;
export const InvoiceInfosDocument = gql`
    query InvoiceInfos($slug: String!, $period: String!) {
  viewer {
    id
    invoiceInfos(slug: $slug, period: $period) {
      id
      name
    }
  }
}
    `;

/**
 * __useInvoiceInfosQuery__
 *
 * To run a query within a React component, call `useInvoiceInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceInfosQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useInvoiceInfosQuery(baseOptions: Apollo.QueryHookOptions<TInvoiceInfosQuery, TInvoiceInfosQueryVariables> & ({ variables: TInvoiceInfosQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TInvoiceInfosQuery, TInvoiceInfosQueryVariables>(InvoiceInfosDocument, options);
      }
export function useInvoiceInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TInvoiceInfosQuery, TInvoiceInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TInvoiceInfosQuery, TInvoiceInfosQueryVariables>(InvoiceInfosDocument, options);
        }
export function useInvoiceInfosSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TInvoiceInfosQuery, TInvoiceInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TInvoiceInfosQuery, TInvoiceInfosQueryVariables>(InvoiceInfosDocument, options);
        }
export type InvoiceInfosQueryHookResult = ReturnType<typeof useInvoiceInfosQuery>;
export type InvoiceInfosLazyQueryHookResult = ReturnType<typeof useInvoiceInfosLazyQuery>;
export type InvoiceInfosSuspenseQueryHookResult = ReturnType<typeof useInvoiceInfosSuspenseQuery>;
export type InvoiceInfosQueryResult = Apollo.QueryResult<TInvoiceInfosQuery, TInvoiceInfosQueryVariables>;
export const CampaignListDocument = gql`
    query CampaignList($q: String, $category: String, $cursor: String) {
  search {
    ...SearchResultCampaigns
    totalCount(q: $q, category: $category)
  }
}
    ${SearchResultCampaignsFragmentDoc}
${PageInfoFieldsFragmentDoc}`;

/**
 * __useCampaignListQuery__
 *
 * To run a query within a React component, call `useCampaignListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignListQuery({
 *   variables: {
 *      q: // value for 'q'
 *      category: // value for 'category'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useCampaignListQuery(baseOptions?: Apollo.QueryHookOptions<TCampaignListQuery, TCampaignListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TCampaignListQuery, TCampaignListQueryVariables>(CampaignListDocument, options);
      }
export function useCampaignListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TCampaignListQuery, TCampaignListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TCampaignListQuery, TCampaignListQueryVariables>(CampaignListDocument, options);
        }
export function useCampaignListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TCampaignListQuery, TCampaignListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TCampaignListQuery, TCampaignListQueryVariables>(CampaignListDocument, options);
        }
export type CampaignListQueryHookResult = ReturnType<typeof useCampaignListQuery>;
export type CampaignListLazyQueryHookResult = ReturnType<typeof useCampaignListLazyQuery>;
export type CampaignListSuspenseQueryHookResult = ReturnType<typeof useCampaignListSuspenseQuery>;
export type CampaignListQueryResult = Apollo.QueryResult<TCampaignListQuery, TCampaignListQueryVariables>;
export const ManagedCampaignDocument = gql`
    query ManagedCampaign($slug: String!, $newsCursor: String, $invoicesCursor: String) {
  viewer {
    id
    firstName
    lastName
    nameLocked
    campaign(slug: $slug) {
      news(first: 100, after: $newsCursor) {
        pageInfo {
          ...PageInfoFields
        }
        edges {
          cursor
          node {
            ...NewsFields
          }
        }
      }
      invoices(first: 120, after: $invoicesCursor) {
        pageInfo {
          ...PageInfoFields
        }
        edges {
          cursor
          node {
            period
            monthYear
            year
            corrected
          }
        }
      }
      ...CampaignFields
      ...InvoiceAddress
      ...ManagedAccount
    }
  }
}
    ${PageInfoFieldsFragmentDoc}
${NewsFieldsFragmentDoc}
${CampaignFieldsFragmentDoc}
${InvoiceAddressFragmentDoc}
${ManagedAccountFragmentDoc}`;

/**
 * __useManagedCampaignQuery__
 *
 * To run a query within a React component, call `useManagedCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagedCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagedCampaignQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      newsCursor: // value for 'newsCursor'
 *      invoicesCursor: // value for 'invoicesCursor'
 *   },
 * });
 */
export function useManagedCampaignQuery(baseOptions: Apollo.QueryHookOptions<TManagedCampaignQuery, TManagedCampaignQueryVariables> & ({ variables: TManagedCampaignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TManagedCampaignQuery, TManagedCampaignQueryVariables>(ManagedCampaignDocument, options);
      }
export function useManagedCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TManagedCampaignQuery, TManagedCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TManagedCampaignQuery, TManagedCampaignQueryVariables>(ManagedCampaignDocument, options);
        }
export function useManagedCampaignSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TManagedCampaignQuery, TManagedCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TManagedCampaignQuery, TManagedCampaignQueryVariables>(ManagedCampaignDocument, options);
        }
export type ManagedCampaignQueryHookResult = ReturnType<typeof useManagedCampaignQuery>;
export type ManagedCampaignLazyQueryHookResult = ReturnType<typeof useManagedCampaignLazyQuery>;
export type ManagedCampaignSuspenseQueryHookResult = ReturnType<typeof useManagedCampaignSuspenseQuery>;
export type ManagedCampaignQueryResult = Apollo.QueryResult<TManagedCampaignQuery, TManagedCampaignQueryVariables>;
export const ManagedCampaignsDocument = gql`
    query ManagedCampaigns {
  viewer {
    id
    campaigns(first: 32) {
      edges {
        node {
          id
          description
          photoUrl(variant: "small")
          slug
          title
          state
          receivedCents
          goalCents
        }
      }
    }
  }
}
    `;

/**
 * __useManagedCampaignsQuery__
 *
 * To run a query within a React component, call `useManagedCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagedCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagedCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useManagedCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<TManagedCampaignsQuery, TManagedCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TManagedCampaignsQuery, TManagedCampaignsQueryVariables>(ManagedCampaignsDocument, options);
      }
export function useManagedCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TManagedCampaignsQuery, TManagedCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TManagedCampaignsQuery, TManagedCampaignsQueryVariables>(ManagedCampaignsDocument, options);
        }
export function useManagedCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TManagedCampaignsQuery, TManagedCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TManagedCampaignsQuery, TManagedCampaignsQueryVariables>(ManagedCampaignsDocument, options);
        }
export type ManagedCampaignsQueryHookResult = ReturnType<typeof useManagedCampaignsQuery>;
export type ManagedCampaignsLazyQueryHookResult = ReturnType<typeof useManagedCampaignsLazyQuery>;
export type ManagedCampaignsSuspenseQueryHookResult = ReturnType<typeof useManagedCampaignsSuspenseQuery>;
export type ManagedCampaignsQueryResult = Apollo.QueryResult<TManagedCampaignsQuery, TManagedCampaignsQueryVariables>;
export const OnboardingCampaignDocument = gql`
    query OnboardingCampaign {
  viewer {
    id
    firstName
    lastName
    nameLocked
    onboardingCampaign {
      news(first: 1) {
        pageInfo {
          ...PageInfoFields
        }
        edges {
          cursor
          node {
            ...NewsFields
          }
        }
      }
      invoices(first: 1) {
        pageInfo {
          ...PageInfoFields
        }
        edges {
          cursor
          node {
            period
            monthYear
            year
            corrected
          }
        }
      }
      ...CampaignFields
      ...InvoiceAddress
      ...ManagedAccount
    }
  }
}
    ${PageInfoFieldsFragmentDoc}
${NewsFieldsFragmentDoc}
${CampaignFieldsFragmentDoc}
${InvoiceAddressFragmentDoc}
${ManagedAccountFragmentDoc}`;

/**
 * __useOnboardingCampaignQuery__
 *
 * To run a query within a React component, call `useOnboardingCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingCampaignQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingCampaignQuery(baseOptions?: Apollo.QueryHookOptions<TOnboardingCampaignQuery, TOnboardingCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TOnboardingCampaignQuery, TOnboardingCampaignQueryVariables>(OnboardingCampaignDocument, options);
      }
export function useOnboardingCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TOnboardingCampaignQuery, TOnboardingCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TOnboardingCampaignQuery, TOnboardingCampaignQueryVariables>(OnboardingCampaignDocument, options);
        }
export function useOnboardingCampaignSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TOnboardingCampaignQuery, TOnboardingCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TOnboardingCampaignQuery, TOnboardingCampaignQueryVariables>(OnboardingCampaignDocument, options);
        }
export type OnboardingCampaignQueryHookResult = ReturnType<typeof useOnboardingCampaignQuery>;
export type OnboardingCampaignLazyQueryHookResult = ReturnType<typeof useOnboardingCampaignLazyQuery>;
export type OnboardingCampaignSuspenseQueryHookResult = ReturnType<typeof useOnboardingCampaignSuspenseQuery>;
export type OnboardingCampaignQueryResult = Apollo.QueryResult<TOnboardingCampaignQuery, TOnboardingCampaignQueryVariables>;
export const CampaignReceivedCentsDocument = gql`
    query CampaignReceivedCents($slug: String!) {
  campaign(slug: $slug) {
    id
    receivedCents
  }
}
    `;

/**
 * __useCampaignReceivedCentsQuery__
 *
 * To run a query within a React component, call `useCampaignReceivedCentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignReceivedCentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignReceivedCentsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCampaignReceivedCentsQuery(baseOptions: Apollo.QueryHookOptions<TCampaignReceivedCentsQuery, TCampaignReceivedCentsQueryVariables> & ({ variables: TCampaignReceivedCentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TCampaignReceivedCentsQuery, TCampaignReceivedCentsQueryVariables>(CampaignReceivedCentsDocument, options);
      }
export function useCampaignReceivedCentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TCampaignReceivedCentsQuery, TCampaignReceivedCentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TCampaignReceivedCentsQuery, TCampaignReceivedCentsQueryVariables>(CampaignReceivedCentsDocument, options);
        }
export function useCampaignReceivedCentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TCampaignReceivedCentsQuery, TCampaignReceivedCentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TCampaignReceivedCentsQuery, TCampaignReceivedCentsQueryVariables>(CampaignReceivedCentsDocument, options);
        }
export type CampaignReceivedCentsQueryHookResult = ReturnType<typeof useCampaignReceivedCentsQuery>;
export type CampaignReceivedCentsLazyQueryHookResult = ReturnType<typeof useCampaignReceivedCentsLazyQuery>;
export type CampaignReceivedCentsSuspenseQueryHookResult = ReturnType<typeof useCampaignReceivedCentsSuspenseQuery>;
export type CampaignReceivedCentsQueryResult = Apollo.QueryResult<TCampaignReceivedCentsQuery, TCampaignReceivedCentsQueryVariables>;
export const CategoryListDocument = gql`
    query CategoryList {
  categories {
    id
    title
    slug
  }
}
    `;

/**
 * __useCategoryListQuery__
 *
 * To run a query within a React component, call `useCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<TCategoryListQuery, TCategoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TCategoryListQuery, TCategoryListQueryVariables>(CategoryListDocument, options);
      }
export function useCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TCategoryListQuery, TCategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TCategoryListQuery, TCategoryListQueryVariables>(CategoryListDocument, options);
        }
export function useCategoryListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TCategoryListQuery, TCategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TCategoryListQuery, TCategoryListQueryVariables>(CategoryListDocument, options);
        }
export type CategoryListQueryHookResult = ReturnType<typeof useCategoryListQuery>;
export type CategoryListLazyQueryHookResult = ReturnType<typeof useCategoryListLazyQuery>;
export type CategoryListSuspenseQueryHookResult = ReturnType<typeof useCategoryListSuspenseQuery>;
export type CategoryListQueryResult = Apollo.QueryResult<TCategoryListQuery, TCategoryListQueryVariables>;
export const CategoryDocument = gql`
    query Category($slug: String!) {
  category(slug: $slug) {
    id
    cta
    description
    title
    slug
  }
}
    `;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions: Apollo.QueryHookOptions<TCategoryQuery, TCategoryQueryVariables> & ({ variables: TCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TCategoryQuery, TCategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TCategoryQuery, TCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TCategoryQuery, TCategoryQueryVariables>(CategoryDocument, options);
        }
export function useCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TCategoryQuery, TCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TCategoryQuery, TCategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategorySuspenseQueryHookResult = ReturnType<typeof useCategorySuspenseQuery>;
export type CategoryQueryResult = Apollo.QueryResult<TCategoryQuery, TCategoryQueryVariables>;
export const GermanCityDocument = gql`
    query GermanCity($zipCode: String!) {
  city(zipCode: $zipCode)
}
    `;

/**
 * __useGermanCityQuery__
 *
 * To run a query within a React component, call `useGermanCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGermanCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGermanCityQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useGermanCityQuery(baseOptions: Apollo.QueryHookOptions<TGermanCityQuery, TGermanCityQueryVariables> & ({ variables: TGermanCityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TGermanCityQuery, TGermanCityQueryVariables>(GermanCityDocument, options);
      }
export function useGermanCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TGermanCityQuery, TGermanCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TGermanCityQuery, TGermanCityQueryVariables>(GermanCityDocument, options);
        }
export function useGermanCitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TGermanCityQuery, TGermanCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TGermanCityQuery, TGermanCityQueryVariables>(GermanCityDocument, options);
        }
export type GermanCityQueryHookResult = ReturnType<typeof useGermanCityQuery>;
export type GermanCityLazyQueryHookResult = ReturnType<typeof useGermanCityLazyQuery>;
export type GermanCitySuspenseQueryHookResult = ReturnType<typeof useGermanCitySuspenseQuery>;
export type GermanCityQueryResult = Apollo.QueryResult<TGermanCityQuery, TGermanCityQueryVariables>;
export const DonationDocument = gql`
    query Donation($token: String!) {
  donation(token: $token) {
    id
    amountCents
    codonationCents(token: $token)
    createdAt
    state
    notify
    receiptRequested
    paymentMethod
    subscribeToOrg
    privateDonor(token: $token) {
      id
      comment
      firstName
      fullName
      imageUrl
      paymentMethod
      showUser
    }
    campaign {
      id
      slug
      title
      ownerName
      goalCents
      receivedCents
      category
      photoUrl(variant: "medium")
    }
  }
}
    `;

/**
 * __useDonationQuery__
 *
 * To run a query within a React component, call `useDonationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDonationQuery(baseOptions: Apollo.QueryHookOptions<TDonationQuery, TDonationQueryVariables> & ({ variables: TDonationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TDonationQuery, TDonationQueryVariables>(DonationDocument, options);
      }
export function useDonationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TDonationQuery, TDonationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TDonationQuery, TDonationQueryVariables>(DonationDocument, options);
        }
export function useDonationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TDonationQuery, TDonationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TDonationQuery, TDonationQueryVariables>(DonationDocument, options);
        }
export type DonationQueryHookResult = ReturnType<typeof useDonationQuery>;
export type DonationLazyQueryHookResult = ReturnType<typeof useDonationLazyQuery>;
export type DonationSuspenseQueryHookResult = ReturnType<typeof useDonationSuspenseQuery>;
export type DonationQueryResult = Apollo.QueryResult<TDonationQuery, TDonationQueryVariables>;
export const DonationStateDocument = gql`
    query DonationState($token: String!) {
  donation(token: $token) {
    id
    state
  }
}
    `;

/**
 * __useDonationStateQuery__
 *
 * To run a query within a React component, call `useDonationStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationStateQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDonationStateQuery(baseOptions: Apollo.QueryHookOptions<TDonationStateQuery, TDonationStateQueryVariables> & ({ variables: TDonationStateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TDonationStateQuery, TDonationStateQueryVariables>(DonationStateDocument, options);
      }
export function useDonationStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TDonationStateQuery, TDonationStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TDonationStateQuery, TDonationStateQueryVariables>(DonationStateDocument, options);
        }
export function useDonationStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TDonationStateQuery, TDonationStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TDonationStateQuery, TDonationStateQueryVariables>(DonationStateDocument, options);
        }
export type DonationStateQueryHookResult = ReturnType<typeof useDonationStateQuery>;
export type DonationStateLazyQueryHookResult = ReturnType<typeof useDonationStateLazyQuery>;
export type DonationStateSuspenseQueryHookResult = ReturnType<typeof useDonationStateSuspenseQuery>;
export type DonationStateQueryResult = Apollo.QueryResult<TDonationStateQuery, TDonationStateQueryVariables>;
export const DonationListDocument = gql`
    query DonationList($cursor: String, $slug: String!, $state: [String!]) {
  viewer {
    id
    campaign(slug: $slug) {
      id
      slug
      title
      receivedCents
      receivedPaypalCents
      receivedStripeCents
      transactionFeeCents
      manager {
        id
      }
      donations(state: $state, first: 30, after: $cursor) {
        pageInfo {
          ...PageInfoFields
        }
        edges {
          node {
            id
            createdAt
            confirmedAt
            amountCents
            state
            refundable
            refundTriggered
            refundedAt
            privateDonor {
              id
              comment
              firstName
              fullName
              imageUrl
              paymentMethod
              thankYouMessage
            }
          }
        }
      }
    }
  }
}
    ${PageInfoFieldsFragmentDoc}`;

/**
 * __useDonationListQuery__
 *
 * To run a query within a React component, call `useDonationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationListQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      slug: // value for 'slug'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useDonationListQuery(baseOptions: Apollo.QueryHookOptions<TDonationListQuery, TDonationListQueryVariables> & ({ variables: TDonationListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TDonationListQuery, TDonationListQueryVariables>(DonationListDocument, options);
      }
export function useDonationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TDonationListQuery, TDonationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TDonationListQuery, TDonationListQueryVariables>(DonationListDocument, options);
        }
export function useDonationListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TDonationListQuery, TDonationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TDonationListQuery, TDonationListQueryVariables>(DonationListDocument, options);
        }
export type DonationListQueryHookResult = ReturnType<typeof useDonationListQuery>;
export type DonationListLazyQueryHookResult = ReturnType<typeof useDonationListLazyQuery>;
export type DonationListSuspenseQueryHookResult = ReturnType<typeof useDonationListSuspenseQuery>;
export type DonationListQueryResult = Apollo.QueryResult<TDonationListQuery, TDonationListQueryVariables>;
export const NewsItemDocument = gql`
    query NewsItem($id: ID!) {
  news(id: $id) {
    ...NewsFields
    notify
  }
}
    ${NewsFieldsFragmentDoc}`;

/**
 * __useNewsItemQuery__
 *
 * To run a query within a React component, call `useNewsItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewsItemQuery(baseOptions: Apollo.QueryHookOptions<TNewsItemQuery, TNewsItemQueryVariables> & ({ variables: TNewsItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TNewsItemQuery, TNewsItemQueryVariables>(NewsItemDocument, options);
      }
export function useNewsItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TNewsItemQuery, TNewsItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TNewsItemQuery, TNewsItemQueryVariables>(NewsItemDocument, options);
        }
export function useNewsItemSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TNewsItemQuery, TNewsItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TNewsItemQuery, TNewsItemQueryVariables>(NewsItemDocument, options);
        }
export type NewsItemQueryHookResult = ReturnType<typeof useNewsItemQuery>;
export type NewsItemLazyQueryHookResult = ReturnType<typeof useNewsItemLazyQuery>;
export type NewsItemSuspenseQueryHookResult = ReturnType<typeof useNewsItemSuspenseQuery>;
export type NewsItemQueryResult = Apollo.QueryResult<TNewsItemQuery, TNewsItemQueryVariables>;
export const NewsItemsDocument = gql`
    query NewsItems($cursor: String, $slug: String!) {
  campaign(slug: $slug) {
    id
    news(first: 3, after: $cursor) {
      pageInfo {
        ...PageInfoFields
      }
      edges {
        cursor
        node {
          ...NewsFields
        }
      }
    }
  }
}
    ${PageInfoFieldsFragmentDoc}
${NewsFieldsFragmentDoc}`;

/**
 * __useNewsItemsQuery__
 *
 * To run a query within a React component, call `useNewsItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsItemsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useNewsItemsQuery(baseOptions: Apollo.QueryHookOptions<TNewsItemsQuery, TNewsItemsQueryVariables> & ({ variables: TNewsItemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TNewsItemsQuery, TNewsItemsQueryVariables>(NewsItemsDocument, options);
      }
export function useNewsItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TNewsItemsQuery, TNewsItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TNewsItemsQuery, TNewsItemsQueryVariables>(NewsItemsDocument, options);
        }
export function useNewsItemsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TNewsItemsQuery, TNewsItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TNewsItemsQuery, TNewsItemsQueryVariables>(NewsItemsDocument, options);
        }
export type NewsItemsQueryHookResult = ReturnType<typeof useNewsItemsQuery>;
export type NewsItemsLazyQueryHookResult = ReturnType<typeof useNewsItemsLazyQuery>;
export type NewsItemsSuspenseQueryHookResult = ReturnType<typeof useNewsItemsSuspenseQuery>;
export type NewsItemsQueryResult = Apollo.QueryResult<TNewsItemsQuery, TNewsItemsQueryVariables>;
export const namedOperations = {
  Query: {
    Quickinfos: 'Quickinfos' as const,
    Viewer: 'Viewer' as const,
    Campaign: 'Campaign' as const,
    CampaignDonationList: 'CampaignDonationList' as const,
    FeaturedCampaignList: 'FeaturedCampaignList' as const,
    InvoiceInfos: 'InvoiceInfos' as const,
    CampaignList: 'CampaignList' as const,
    ManagedCampaign: 'ManagedCampaign' as const,
    ManagedCampaigns: 'ManagedCampaigns' as const,
    OnboardingCampaign: 'OnboardingCampaign' as const,
    CampaignReceivedCents: 'CampaignReceivedCents' as const,
    CategoryList: 'CategoryList' as const,
    Category: 'Category' as const,
    GermanCity: 'GermanCity' as const,
    Donation: 'Donation' as const,
    DonationState: 'DonationState' as const,
    DonationList: 'DonationList' as const,
    NewsItem: 'NewsItem' as const,
    NewsItems: 'NewsItems' as const
  },
  Mutation: {
    SignIn: 'SignIn' as const,
    SignOut: 'SignOut' as const,
    ContentReport: 'ContentReport' as const,
    CampaignDelete: 'CampaignDelete' as const,
    CampaignDenunciate: 'CampaignDenunciate' as const,
    CampaignEvent: 'CampaignEvent' as const,
    CampaignInquire: 'CampaignInquire' as const,
    RequestPaypalReferral: 'RequestPaypalReferral' as const,
    SendDonorReport: 'SendDonorReport' as const,
    CampaignUpdate: 'CampaignUpdate' as const,
    DonationEdit: 'DonationEdit' as const,
    FinalizePaypal: 'FinalizePaypal' as const,
    DonationMake: 'DonationMake' as const,
    Refund: 'Refund' as const,
    SendThankYouMessage: 'SendThankYouMessage' as const,
    BrowserEvent: 'BrowserEvent' as const,
    NewsDelete: 'NewsDelete' as const,
    NewsMake: 'NewsMake' as const,
    NewsUnsubscribe: 'NewsUnsubscribe' as const,
    NewsUpdate: 'NewsUpdate' as const,
    StripeKYCStartOnboarding: 'StripeKYCStartOnboarding' as const,
    StripeKYCSubmitIndividualData: 'StripeKYCSubmitIndividualData' as const,
    ChangePassword: 'ChangePassword' as const,
    ConfirmEmail: 'ConfirmEmail' as const,
    UserDelete: 'UserDelete' as const,
    PasswordReset: 'PasswordReset' as const,
    RequestPasswordReset: 'RequestPasswordReset' as const,
    ResendConfirmation: 'ResendConfirmation' as const,
    SignUp: 'SignUp' as const,
    UserUpdate: 'UserUpdate' as const
  },
  Fragment: {
    CampaignFields: 'CampaignFields' as const,
    InvoiceAddress: 'InvoiceAddress' as const,
    ManagedAccount: 'ManagedAccount' as const,
    NewsFields: 'NewsFields' as const,
    PageInfoFields: 'PageInfoFields' as const,
    SearchResultCampaigns: 'SearchResultCampaigns' as const
  }
}